import React from 'react';
import clsx from 'clsx';
import OptionItem from '@components/atoms/option-item/option-item';
import styles from './profile-filter-options.module.scss';

type FilterOptions = {
  text: string;
  value: string;
};

type ProfileFilterOptionsProps = {
  onChangeHandler?: (event: Event, type: string, text?: string) => void;
  options: FilterOptions[];
  selectedValue?: string | string[];
};

const ProfileFilterOptions: React.FC<ProfileFilterOptionsProps> = (props): React.JSX.Element => {
  const { options, selectedValue, onChangeHandler } = props;

  return (
    <>
      <ul className={clsx(styles.profileFilterOptions__list)}>
        {options?.length > 0 && (
          <>
            {options?.map((list, index) => (
              <li
                key={list?.value}
                className={styles.profileFilterOptions__listItem}
              >
                {list?.text && (
                  <OptionItem
                    htmlId={`${list?.text}-${index}`}
                    checked={selectedValue?.includes(list?.value)}
                    onChange={(event) => onChangeHandler(event, list?.value, list?.text)}
                    label={list?.text}
                    useFilterRedesignUi={true}
                    customLabelClass={styles.profileFilterOptions__listItem__label}
                  />
                )}
              </li>
            ))}
          </>
        )}
      </ul>
    </>
  );
};

export default ProfileFilterOptions;
