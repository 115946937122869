import { Product } from '@interfaces/models/product';
import React, { FCWithChildren } from 'react';
import clsx from 'clsx';
import ProductCard, { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';
import isUSPBlockPositionMatched from '@helpers/utils/usp-block-matcher';
import Usp from '@components/organisms/usp/usp';
import { PageType } from '@interfaces/common/common';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import useLazyLoadSimilarProducts from '@hooks/product-feed/use-lazy-load-similar-products';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import styles from './products-list.module.scss';

type USPBlockInfo = {
  showUspBlock: boolean;
  uspBlockProductCount: number;
};

export type ProductListProps = {
  products: Product[] | CatalogProduct[];
  pageType: PageType;
  uspBlockInfo?: USPBlockInfo;
  ProductCardWrapper?: FCWithChildren<{ index: number }>;
  children?: React.ReactNode;
  productCardProps?: Partial<ProductCardProps>;
  shouldLazyLoadProducts?: boolean;
  includeSchemaInProductCard?: boolean;
};

const ProductsList: React.FC<ProductListProps> = (props) => {
  const {
    products,
    pageType = 'personalized',
    uspBlockInfo = null,
    ProductCardWrapper = null,
    productCardProps = {},
    shouldLazyLoadProducts = false,
    includeSchemaInProductCard = true,
  } = props;

  const { sliceIndex, onThresholdCrossed, renderedProducts, ProductLazyLoadWrapper } = useLazyLoadSimilarProducts(
    products,
    pageType,
    shouldLazyLoadProducts,
  );

  return (
    <ul
      className={styles.productsList__flexContainer}
      data-cy={`${pageType}__productList__flexContainer`}
    >
      {renderedProducts.map(
        (
          // TODO: Removed Product[] type due to a type issue with FavoriteHotfiltersProductPayload; the API doesn't return id and type fields.
          product,
          productIndex: number,
        ) => (
          <React.Fragment key={product.id}>
            <li
              key={`${product.id}_${productIndex}`}
              className={styles['productsList__flexContainer--item']}
              data-cy={`${pageType}__productList__flexContainer__item`}
            >
              <ConditionalWrapper
                condition={shouldLazyLoadProducts}
                wrap={(children) => (
                  <ProductLazyLoadWrapper
                    index={productIndex}
                    sliceIndex={sliceIndex}
                    onThresholdCrossed={onThresholdCrossed}
                    shouldLazyLoadProducts={shouldLazyLoadProducts}
                  >
                    {children}
                  </ProductLazyLoadWrapper>
                )}
              >
                <ConditionalWrapper
                  condition={ProductCardWrapper !== null}
                  wrap={(children) => <ProductCardWrapper index={productIndex}>{children}</ProductCardWrapper>}
                >
                  <ProductCard
                    customClass={styles.productsList__flexContainer__card}
                    data-cy={`${pageType}__productList__flexContainer__card`}
                    product={product}
                    index={productIndex}
                    showProductTags
                    showSellerBadge
                    showProductSize
                    showProductExpressDelivery
                    showProductLocation={true}
                    showProductDirectShipping
                    showDiscountPrice
                    isFavourite
                    disableLazyLoad={productIndex <= 7}
                    fetchpriority={productIndex <= 7 ? 'high' : 'low'}
                    pageType={pageType}
                    containerId={`product_id_${product.id}`}
                    includeProductSchema={includeSchemaInProductCard}
                    {...productCardProps}
                  />
                </ConditionalWrapper>
              </ConditionalWrapper>
            </li>
            {uspBlockInfo?.showUspBlock &&
              isUSPBlockPositionMatched(productIndex, products.length, uspBlockInfo?.uspBlockProductCount) && (
                <li
                  className={clsx(
                    styles['productsList__flexContainer--item'],
                    styles['productsList__flexContainer--item--uspContainer'],
                  )}
                >
                  <Usp pageType="hero_pdp" />
                </li>
              )}
          </React.Fragment>
        ),
      )}
    </ul>
  );
};

export default React.memo(ProductsList);
