import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useOnClickOutside } from 'usehooks-ts';
import useOnKeyPress from '@hooks/browser-events/use-on-key-press';
import Icon from '@components/atoms/icon/icon';
import clsx from 'clsx';
import styles from './kebab-menu.module.scss';

interface KebabMenuProps {
  closeMenu?: boolean;
  className?: string;
  isMenuOpen?: (open: boolean) => void;
}

const KebabMenu: React.FC<React.PropsWithChildren<KebabMenuProps>> = (props) => {
  const { isMenuOpen, closeMenu = false, className, children } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>();
  const escPress = useOnKeyPress('Escape', ref);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    isMenuOpen(showMenu);
  }, [showMenu]);

  useEffect(() => {
    if (showMenu && closeMenu) {
      setShowMenu(false);
    }
  }, [closeMenu]);

  useEffect(() => {
    if (escPress) {
      setShowMenu(false);
    }
  }, [escPress]);

  useOnClickOutside(ref, () => {
    setShowMenu(false);
  });

  return (
    <div
      className={clsx(styles.kebabMenu, className)}
      role="navigation"
      ref={ref}
    >
      <button
        type="button"
        className={styles.kebabMenu__toggleBtn}
        onClick={() => setShowMenu(!showMenu)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setShowMenu(!showMenu);
          }
        }}
        aria-expanded={showMenu}
      >
        <Icon
          name="three-dots-default"
          className={styles.kebabMenu__toggleBtn__icon}
        />
        <span className="visuallyhidden">{t('KEBAB_MENU.ICON_BUTTON', 'toggle menu')}</span>
      </button>
      <div className={clsx(styles.kebabMenu__menu, showMenu && styles['kebabMenu__menu--show'])}>{children}</div>
    </div>
  );
};

export default KebabMenu;
