import { ElasticSearch, ElasticSearchFilterKeys, ElasticSearchPriceFilters } from '@interfaces/models/elasticSearch';
import { booleanFilters, sizeFilterFacetFields } from '@constants/catalog';
import { FilterTopLevelGroup } from '@interfaces/models/catalogFilter';
import { filterToFacetRename } from '@maps/catalog/search-product-facets-filters-maps';

const isPriceFilters = (
  filterKey: ElasticSearchFilterKeys,
  filterValue: unknown,
): filterValue is ElasticSearchPriceFilters['price'] => filterKey === 'price';

const isBooleanFilters = (filterKey: ElasticSearchFilterKeys, filterValue: unknown): filterValue is boolean =>
  (booleanFilters as ElasticSearchFilterKeys[]).includes(filterKey);

const isArrayFilter = (filterValue: unknown): filterValue is (string | number)[] => Array.isArray(filterValue);

const isSizeTopLevelFilterGroup = (filterKey: string) => filterKey === FilterTopLevelGroup.SIZE;

const isSizeFilter = (filterKey: ElasticSearchFilterKeys) =>
  sizeFilterFacetFields.includes(filterToFacetRename(filterKey));

const getSizeFilterCount = (filters: ElasticSearch['filters']) => {
  let count = 0;
  Object.keys(filters).forEach((filterKey: ElasticSearchFilterKeys) => {
    const filterValue = filters[filterKey];
    if (isSizeFilter(filterKey) && isArrayFilter(filterValue)) {
      count += filterValue.length;
    }
  });

  return count;
};

const getSelectedFiltersCount = ({
  filterKey,
  filters,
}: {
  filterKey: ElasticSearchFilterKeys;
  filters: ElasticSearch['filters'];
}) => {
  if (filterKey === 'catalogLinksWithoutLanguage') {
    return 0;
  }
  if (isSizeTopLevelFilterGroup(filterKey)) {
    return getSizeFilterCount(filters);
  }
  const filterValue = filters[filterKey];

  if (!filterValue) {
    return 0;
  }
  if (isPriceFilters(filterKey, filterValue)) {
    return Object.keys(filterValue || {}).length;
  }
  if (isBooleanFilters(filterKey, filterValue)) {
    return filterValue ? 1 : 0;
  }
  if (isArrayFilter(filterValue)) {
    const count = filterValue?.length || 0;
    if (filterKey === 'materialLvl0.id') {
      return count + (filters['materialLvl1.id']?.length || 0);
    }

    return count;
  }
  return 0;
};

export default getSelectedFiltersCount;
