import { useEffect, useState, MutableRefObject } from 'react';

// TODO: This stuff should probably be deprecated in favor of @usehooks-ts/use-event-listener
const useOnKeyPress = (targetKey: string, target: MutableRefObject<HTMLElement>) => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);
  const downHandler = (event: KeyboardEvent) => {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      event.preventDefault();
    }
    if (event.key === targetKey) {
      setKeyPressed(true);
    }
  };
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    const currentTarget = target?.current;
    if (!currentTarget) {
      return;
    }

    currentTarget.addEventListener('keydown', downHandler);
    currentTarget.addEventListener('keyup', upHandler);

    // Remove event listeners on cleanup
    return () => {
      currentTarget.removeEventListener('keydown', downHandler);
      currentTarget.removeEventListener('keyup', upHandler);
    };
  }, []);

  if (!target) {
    return null;
  }
  return keyPressed;
};

export default useOnKeyPress;
