import React from 'react';
import clsx from 'clsx';
import style from './switch.module.scss';

type SwitchProps = {
  checked: boolean;
  disabled?: boolean;
  ariaLabelledby?: string;
  onChange: (checked: boolean) => void;
  dataCyElementLocator?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'onChange'>;

const Switch: React.FC<SwitchProps> = (props): React.JSX.Element => {
  const { checked, disabled, ariaLabelledby, onChange, dataCyElementLocator } = props;

  const clickHandler = (): void => {
    if (disabled) {
      return;
    }
    onChange(!checked);
  };

  return (
    <button
      type="button"
      aria-labelledby={ariaLabelledby}
      onClick={clickHandler}
      className={clsx(
        style['switch-container'],
        { [style['switch-container__checked']]: checked },
        { [style['switch-container__disabled']]: disabled },
      )}
      data-cy={dataCyElementLocator}
    >
      <div
        className={clsx(
          style['switch-handle'],
          { [style['switch-handle__checked']]: checked },
          { [style['switch-handle__disabled']]: disabled },
        )}
      />
    </button>
  );
};

export default Switch;
