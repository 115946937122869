import React from 'react';
import dynamic from 'next/dynamic';
import UserFollowButton from '@components/molecules/products/user-follow-button/user-follow-button';
import useProfileQuery from '@hooks/profile/use-profile-query';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import { ModalContext } from '@context/modal.context';
import clsx from 'clsx';
import AuthenticationGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { CommunityTabs } from '../profile-community-modal/profile-community-modal';
import styles from './profile-community-block-buttons.module.scss';

const ProfileCommunityModal = dynamic(
  () => import('@components/organisms/profile/profile-community/profile-community-modal/profile-community-modal'),
  {
    ssr: false,
  },
);

interface ProfileCommunityBlockButtonsProps {
  className?: string;
}

const ProfileCommunityBlockButtons: React.FC<ProfileCommunityBlockButtonsProps> = ({ className }) => {
  const { profile, isUserProfile } = useProfileQuery();
  const { t } = useTranslation('profile');
  const { sendAnalyticEvent: sendProfileAnalyticEvent } = useAnalyticEvents('profile_page');

  const handleFollowTracking = (isFollow: boolean) => {
    sendProfileAnalyticEvent('follow_profile', {
      action: isFollow ? 'follow_profile' : 'unfollow_profile',
      label: profile?.id,
      property: 'from_top_section',
    });
  };

  const openModal = useContextSelector(ModalContext, (v) => v.openModal);
  const openCommunityModal = (tab: CommunityTabs) =>
    openModal(<ProfileCommunityModal selectedTab={tab} />, {
      styles: {
        content: {
          maxWidth: '660px',
          maxHeight: '600px',
          width: '100%',
          overflowY: 'hidden',
        },
      },
    });
  return (
    <div className={clsx(styles.profileCommunityBlock, className)}>
      <button
        className={styles.profileCommunityBlock__btn}
        type="button"
        onClick={() => openCommunityModal('followers')}
        data-cy="profile-community-followers__button"
      >
        <b data-cy="profile-community-followers__count">{profile?.social?.nbFollowers}</b>{' '}
        {profile?.social?.nbFollowers > 1 ? t('PROFILE.TOP.FOLLOWERS') : t('PROFILE.TOP.FOLLOWERS_SINGULAR')}
      </button>
      <button
        className={styles.profileCommunityBlock__btn}
        type="button"
        onClick={() => openCommunityModal('following')}
        data-cy="profile-community-following__button"
      >
        <b data-cy="profile-community-following__count">{profile?.social?.nbFollows}</b>
        {t('PROFILE.TOP.FOLLOWING')}
      </button>
      {!isUserProfile && (
        <AuthenticationGuard className={styles.profileCommunityBlock__followBtn}>
          <UserFollowButton
            sellerId={profile?.id}
            isProfilePage={true}
            handleClick={handleFollowTracking}
          />
        </AuthenticationGuard>
      )}
    </div>
  );
};

export default ProfileCommunityBlockButtons;
