import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import ProductCardSkeleton from '@components/organisms/catalog/components/skeleton-components/product-card-skeleton/product-card-skeleton';
import Button from '@components/atoms/button/button';
import ProductsList, { ProductListProps } from '@components/generic-product-feed/products-list/products-list';
import ProductFeedEmptyState, {
  EmptyState,
} from '@components/generic-product-feed/product-feed-empty-state/product-feed-empty-state';
import { ToastMessageProvider } from '@context/toast-message.context';
import styles from './product-feed.module.scss';

type SchemaOptions = {
  includeSchemaInProductCard?: boolean;
  includeSchemaInProductFeed?: boolean;
  baseUrlForItemSchema?: string;
};

type ProductFeedProps = {
  viewMoreVisible?: boolean;
  viewMoreCTAText?: string;
  isLoading?: boolean;
  similarProductTitle?: string;
  emptyState: EmptyState;
  handleViewMoreClick?: (args?: number) => void;
  isLoadingMore?: boolean;
  shouldLazyLoadProducts?: boolean;
  schemaOptions?: SchemaOptions;
  isErrorLoadingProducts?: boolean;
  errorToastComponent?: React.ReactNode;
} & ProductListProps &
  React.HTMLProps<HTMLDivElement>;

const ProductFeed: React.FC<ProductFeedProps> = (props) => {
  const {
    products,
    viewMoreVisible = false,
    viewMoreCTAText,
    similarProductTitle,
    handleViewMoreClick,
    isLoading = false,
    isLoadingMore = false,
    ProductCardWrapper,
    pageType,
    uspBlockInfo = null,
    emptyState = null,
    productCardProps = {},
    shouldLazyLoadProducts = false,
    schemaOptions = null,
    isErrorLoadingProducts = false,
    errorToastComponent,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {similarProductTitle && (
        <div className={clsx(styles.productFeed__similarProductTitle, 'vc-title-s')}>{t(similarProductTitle)}</div>
      )}
      <div className={styles.productFeed__container}>
        <ToastMessageProvider>
          <>
            <div className={styles.productFeed__resultContainer}>
              {isLoading ? (
                <ul className={styles.productFeed__loader}>
                  {Array.from(Array(8).keys()).map((key) => (
                    <li key={key}>
                      <ProductCardSkeleton />
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  {products?.length ? (
                    <ProductsList
                      pageType={pageType}
                      products={products}
                      uspBlockInfo={uspBlockInfo}
                      ProductCardWrapper={ProductCardWrapper}
                      productCardProps={productCardProps}
                      shouldLazyLoadProducts={shouldLazyLoadProducts}
                      includeSchemaInProductCard={schemaOptions?.includeSchemaInProductCard}
                    />
                  ) : (
                    <ProductFeedEmptyState emptyState={emptyState} />
                  )}
                  {isErrorLoadingProducts && errorToastComponent}
                </>
              )}
            </div>

            {viewMoreVisible && (
              <div className={styles.productFeedPage__viewMore}>
                <Button
                  variant="primary"
                  size="large"
                  disabled={isLoadingMore || isLoading}
                  className={styles.productFeedPage__viewMore__button}
                  onClick={() => handleViewMoreClick?.(products.length)}
                  data-cy={`${pageType}__view__more`}
                >
                  {t(viewMoreCTAText)}
                </Button>
              </div>
            )}
          </>
        </ToastMessageProvider>
      </div>
    </>
  );
};

export default ProductFeed;
