import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Icon from '@components/atoms/icon/icon';
import PaginationButton from '@components/catalog/pagination/components/pagination-button/pagination-button';
import styles from './pagination.module.scss';

type PaginationProps = {
  currentPage: number;
  pageCount: number;
  buttonProps?: React.HTMLAttributes<HTMLButtonElement>;
  onChange?: (page: number) => void;
  hasArrows?: boolean;
  showText?: boolean;
  buttonsToShow?: number;
  customActiveStyle?: boolean;
  behaviour?: 'catalog' | 'default';
} & React.HTMLProps<HTMLDivElement>;

const Pagination: React.FC<PaginationProps> = (props): React.JSX.Element => {
  const {
    currentPage: page,
    pageCount,
    onChange,
    hasArrows = true,
    showText = false,
    buttonsToShow = 6,
    customActiveStyle,
    behaviour = 'default',
    ...rest
  } = props;

  const { t } = useTranslation();

  const currentPage = typeof page !== 'number' || page <= 0 ? 1 : page;
  const hasBackArrow: boolean = hasArrows && currentPage !== 1;
  const hasForwardArrow: boolean = hasArrows && currentPage !== pageCount;
  const maxAmountToShow: number = buttonsToShow % 2 === 0 ? buttonsToShow / 2 : (buttonsToShow - 1) / 2;

  const shouldRenderAllButtons: boolean = (() => {
    if (behaviour === 'catalog') {
      return pageCount <= buttonsToShow + 2;
    }
    return pageCount <= buttonsToShow;
  })();

  const shouldShowLeftDots: boolean = (() => {
    if (shouldRenderAllButtons) {
      return false;
    }
    if (behaviour === 'catalog') {
      return currentPage - 1 > 1;
    }
    return currentPage - maxAmountToShow > 1;
  })();

  const shouldShowRightDots: boolean = (() => {
    if (shouldRenderAllButtons) {
      return false;
    }
    if (behaviour === 'catalog') {
      return pageCount - currentPage > buttonsToShow;
    }
    return currentPage + maxAmountToShow < pageCount;
  })();

  const displayedElements = useMemo<number[]>(() => {
    // If we have less pages than buttons we're willing to show, just display all of them, except first and last
    if (shouldRenderAllButtons) {
      return [...Array(pageCount)]
        .map((el: number, index: number) => index + 1)
        .filter((index: number) => !(index === 1 || index === pageCount));
    }

    const toShow: number[] = [];
    let totalCurrentToLeft: number;
    let totalCurrentToRight: number;

    if (behaviour === 'catalog') {
      totalCurrentToLeft = currentPage - 1 > 1 ? 0 : 1;
      totalCurrentToRight = pageCount - buttonsToShow + 1;
    } else {
      // We determine which buttons to show based on the following:
      // maxAmountToShow - how many buttons we'd like to show on each side of currently selected button. Thing to note here is
      // if we're not showing all possible buttons on one side, we need to add missing buttons to the other, while not counting
      // first and last buttons
      // example: if maxAmountToShow is 4, and we're on page No. 3, we show the following:
      // < 1 2 |3| 4 5 6 7 8 9 10 ... pageCount >
      // So, 1 element to the left, and 4 + 3 to the right to get the wanted 2 * maxAmountToShow

      const currentToLeftDifference: number = currentPage - maxAmountToShow > 1 ? maxAmountToShow : currentPage - 1;
      const currentToRightDifference: number =
        currentPage + maxAmountToShow < pageCount ? maxAmountToShow : pageCount - currentPage;

      // If some buttons on one side are missing, we pad the other side
      totalCurrentToLeft =
        currentToLeftDifference +
        (currentToRightDifference >= maxAmountToShow ? 0 : maxAmountToShow - currentToRightDifference) -
        (shouldShowRightDots ? 1 : 0);
      totalCurrentToRight =
        currentToRightDifference +
        (currentToLeftDifference >= maxAmountToShow ? 0 : maxAmountToShow - currentToLeftDifference) -
        (shouldShowLeftDots ? 1 : 0);
    }

    for (let i = currentPage - totalCurrentToLeft; i <= currentPage + totalCurrentToRight; i++) {
      if (i > 1 && i < pageCount) {
        toShow.push(i);
      }
    }
    return toShow;
  }, [currentPage, pageCount, buttonsToShow]);

  const shouldShowPageCount: boolean = !displayedElements.includes(pageCount);

  const set = (page: number): void => {
    onChange?.(page);
  };

  return (
    <div
      className={styles.pagination}
      data-cy="pagination-container"
      {...rest}
    >
      {hasArrows && (
        <PaginationButton
          tabIndex={0}
          disabled={!hasBackArrow}
          onClick={() => set(currentPage - 1)}
          showText={showText}
          isArrow
          customActiveStyle={customActiveStyle}
          data-cy="pagination-left-arrow-btn"
          currentPage={currentPage - 1}
        >
          <Icon name="arrow-head-left" />
          {showText && <span>{t('FILTERS.PAGINATION.PREVIOUS')}</span>}
        </PaginationButton>
      )}

      <PaginationButton
        current={currentPage === 1}
        onClick={() => set(1)}
        customActiveStyle={customActiveStyle}
        currentPage={currentPage}
      >
        1
      </PaginationButton>

      {shouldShowLeftDots && <span>...</span>}

      {displayedElements.map((el) => (
        <PaginationButton
          key={el}
          current={el === currentPage}
          onClick={() => set(el)}
          customActiveStyle={customActiveStyle}
          currentPage={el}
        >
          {el}
        </PaginationButton>
      ))}

      {shouldShowRightDots && <span>...</span>}

      {shouldShowPageCount && pageCount > 1 && (
        <PaginationButton
          current={currentPage === pageCount}
          tabIndex={0}
          customActiveStyle={customActiveStyle}
          onClick={() => set(pageCount)}
          currentPage={pageCount}
        >
          {pageCount}
        </PaginationButton>
      )}

      {hasArrows && (
        <PaginationButton
          tabIndex={0}
          disabled={!hasForwardArrow}
          onClick={() => set(currentPage + 1)}
          showText={showText}
          isArrow
          customActiveStyle={customActiveStyle}
          currentPage={currentPage + 1}
          data-cy="pagination-right-arrow-btn"
        >
          {showText && <span>{t('FILTERS.PAGINATION.NEXT')}</span>}
          <Icon name="arrow-head-right" />
        </PaginationButton>
      )}
    </div>
  );
};

export default Pagination;
