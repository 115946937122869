import React from 'react';
import {
  ElasticSearch,
  ElasticSearchFacetsFields,
  ElasticSearchFilterKeys,
  ElasticSearchStringListFilterProperties,
  isPriceFilterKey,
} from '@interfaces/models/elasticSearch';
import { facetToFilterRename, filterToFacetRename } from '@maps/catalog/search-product-facets-filters-maps';
import { isBooleanFilterKey, isStringListFilterKey } from '@constants/catalog';
import {
  ElasticSearchFacet,
  ElasticSearchFacetFieldResponse,
  ElasticSearchResponse,
} from '@interfaces/api/responses/elasticSearch';
import { usePreferences } from '@context/preferences.context';
import { chainMethods } from '@helpers/utils/general';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { StaticFilterGroups } from '@hooks/catalog-context/use-static-filter-groups';
import useMarketingFiltersQuery from '@hooks/marketing-filters/use-marketing-filters-query';
import { pickSearchContextAttributes } from '@helpers/utils/catalog/catalog-tracking-utils';

interface UpdateFiltersCallbacks {
  isManualSwitchRef: React.MutableRefObject<boolean>;
  filters: ElasticSearch['filters'];
  setFilters: React.Dispatch<React.SetStateAction<ElasticSearch['filters']>>;
  facets: ElasticSearchResponse['facets']['fields'];
  areMySizesEnabled: boolean;
  applicableSizeIds: number[];
  staticFilterGroups: StaticFilterGroups;
}

const getAnalyticsCompliantFilterValue = ({
  filterGroup,
  filterName,
  filterIds,
}: {
  filterGroup: ElasticSearchFacetsFields;
  filterIds?: string[];
  filterName?: string;
}): string => {
  switch (filterGroup) {
    case 'stock':
      return '1';
    case 'sold':
      return filterName;
    case 'localCountries':
      return 'true';
    case 'directShippingEligible':
      return filterName;
    default:
      return filterIds && filterIds[0] ? String(filterIds[0]) : '';
  }
};

const removeFilterGroup = (filters: ElasticSearch['filters'], key: ElasticSearchFilterKeys, condition = true) => {
  if (condition) {
    const { [key]: removed, ...finalState } = filters || {};
    return finalState;
  }
  return filters;
};

const removeChildFilters = ({
  filters,
  parentKey,
  childKey,
  filterGroupName,
  filterIds,
  facets,
}: {
  filters: ElasticSearch['filters'];
  parentKey: ElasticSearchStringListFilterProperties;
  childKey: ElasticSearchStringListFilterProperties;
  filterGroupName: ElasticSearchStringListFilterProperties;
  filterIds?: string[];
  facets: ElasticSearchFacetFieldResponse;
}) => {
  const parentID = filterIds?.[0];
  if (filterGroupName !== parentKey || !parentID) {
    return filters;
  }
  const childFacetKey = filterToFacetRename(childKey);

  if (filters.hasOwnProperty(childKey) && facets.hasOwnProperty(childFacetKey)) {
    const facetValues = facets[childFacetKey];

    const newState: ElasticSearch['filters'] = {
      ...filters,
      ...{
        [childKey]: filters[childKey].filter((filter) => {
          const facet = facetValues.find((facet) => String(facet.id) === String(filter));
          return facet && String(facet.parentID) !== String(parentID);
        }),
      },
    };

    // If there are still ids for a filterGroup selected, keep the property
    if (newState[childKey].length > 0) {
      return newState;
    }

    return removeFilterGroup(newState, childKey);
  }
  return filters;
};

type SendTrackingEvent = (newFilterState: ElasticSearch['filters'], filterType?: string) => void;

const useUpdateFilters = ({
  isManualSwitchRef,
  filters,
  setFilters,
  facets,
  staticFilterGroups,
  areMySizesEnabled,
  applicableSizeIds,
}: UpdateFiltersCallbacks) => {
  const { sendAnalyticEvent } = useAnalyticEvents('catalog');
  const { currency } = usePreferences();
  const { localToLocalFilter, freeShippingFilter } = useMarketingFiltersQuery();

  const sendSnowplowEvent = (filter_details: ElasticSearch['filters'], filters_applied: { [key: string]: string }) => {
    sendAnalyticEvent('apply_filter', {
      are_filters_active: 'true',
      filters_applied: JSON.stringify(filters_applied),
      filters_details: JSON.stringify(filter_details),
      ...pickSearchContextAttributes([
        'keyword',
        'index_name',
        'catalog_brand',
        'catalog_universe',
        'catalog_category',
        'catalog_subcategory',
        'nb_results',
        'search_query_id',
        'search_session_id',
      ]),
    });
  };

  /***
  START
  HELPER METHODS FOR HANDLING TOGGLE FILTERS
   *****/
  const removePriceChipFilter = (filterIds?: string[]) => {
    const currentPriceFilter = filters.price || {};

    if (isPriceFilterKey(filterIds[0]) && currentPriceFilter.hasOwnProperty(filterIds[0])) {
      const { [filterIds[0]]: removed, ...newPrice } = currentPriceFilter;

      // if all price filters are removed, remove the price filter from the filters object
      if (Object.keys(newPrice).length === 0) {
        setFilters(removeFilterGroup(filters, 'price'));
        return;
      }
      setFilters({
        ...filters,
        ...{ price: newPrice },
      });
    }
  };

  const filterFreeShipping = (sendTrackingEvent: SendTrackingEvent, isChecked: boolean) => {
    if (isChecked) {
      const newState = chainMethods<ElasticSearch['filters']>(
        (f: ElasticSearch['filters']) => removeFilterGroup(f, 'price'),
        (f: ElasticSearch['filters']) => removeFilterGroup(f, 'country'),
        (f: ElasticSearch['filters']) => removeFilterGroup(f, 'localCountries'),
      )(filters);
      setFilters(newState);
      return;
    }
    const newState: ElasticSearch['filters'] = {
      ...filters,
      localCountries: true,
      price: { '>=': 20000 },
      country: ['US'],
    };
    setFilters(newState);
    sendTrackingEvent(newState);
  };

  const filterLocalCountries = (sendTrackingEvent: SendTrackingEvent) => {
    const localCountriesList = localToLocalFilter?.attributes.countries;
    const country = filters?.country?.filter((isoCode) => localCountriesList.includes(isoCode));
    const newState: ElasticSearch['filters'] = {
      ...filters,
      localCountries: true,
      ...{ country: country },
    };
    setFilters(newState);
    sendTrackingEvent(newState);
  };

  const handleDirectShippingFilter = (sendTrackingEvent: SendTrackingEvent) => {
    if (filters.hasOwnProperty('directShippingCountries') && filters.hasOwnProperty('directShippingEligible')) {
      const { directShippingCountries, directShippingEligible, ...newState } = filters;

      setFilters(newState);
      return;
    }

    const newState: ElasticSearch['filters'] = {
      ...filters,
      directShippingCountries: true,
      // TODO: This can be removed when FENX go 100% on prod now we need to have this to handle links and filter that come from ngx
      directShippingEligible: true,
    };

    setFilters(newState);
    sendTrackingEvent(newState, 'dseligiblecountries');
  };

  const handleBooleanFilter = (filterGroupName: ElasticSearchFilterKeys, sendTrackingEvent: SendTrackingEvent) => {
    // Remove boolean property
    if (filters.hasOwnProperty(filterGroupName)) {
      setFilters((prevState: ElasticSearch['filters']) => removeFilterGroup(prevState, filterGroupName));
      return;
    }

    const newState: ElasticSearch['filters'] = {
      ...filters,
      [filterGroupName]: true,
    };

    setFilters(newState);
    sendTrackingEvent(newState);
  };

  const uncheckStringListFilter = (
    filterGroupName: ElasticSearchStringListFilterProperties,
    filterIds?: string[],
    filterName?: string,
  ) => {
    setFilters((prevState: ElasticSearch['filters']) => {
      const newState: ElasticSearch['filters'] = {
        ...prevState,
        [filterGroupName]: prevState[filterGroupName].filter((filter) => !filterIds.includes(filter)),
      };

      // Uncheck all models with the same name
      if (filterGroupName === 'model.id') {
        const modelsToUncheck = facets.model
          .filter((model: ElasticSearchFacet) => model.name === filterName)
          .map((model: ElasticSearchFacet) => model.id);

        const remainingModels = newState['model.id'].filter((modelId: string) => !modelsToUncheck.includes(modelId));

        if (remainingModels.length) {
          return { ...prevState, 'model.id': remainingModels };
        }
        return removeFilterGroup(newState, 'model.id');
      }

      // If there are still ids for a filterGroup selected, keep the property
      if (newState[filterGroupName].length > 0) {
        return chainMethods<ElasticSearch['filters']>(
          // remove child filters for materialLvl0
          (f) =>
            removeChildFilters({
              filters: f,
              parentKey: 'materialLvl0.id',
              childKey: 'materialLvl1.id',
              filterGroupName,
              filterIds,
              facets,
            }),
          // TODO: remove child filters for category and designer filters also!
        )(newState);
      }

      const modifiedState = chainMethods<ElasticSearch['filters']>(
        // if final categoryLvl1 is unchecked, remove categoryLvl2
        (f) =>
          removeFilterGroup(
            f,
            'categoryLvl2.id',
            filterGroupName === 'categoryLvl1.id' && f.hasOwnProperty('categoryLvl2.id'),
          ),
        // if final categoryLvl0 is unchecked, remove categoryLvl1
        (f) =>
          removeFilterGroup(
            f,
            'categoryLvl1.id',
            filterGroupName === 'categoryLvl0.id' && f.hasOwnProperty('categoryLvl1.id'),
          ),
        // if final materialLvl0 is unchecked, remove materialLvl1
        (f) =>
          removeFilterGroup(
            f,
            'materialLvl1.id',
            filterGroupName === 'materialLvl0.id' && f.hasOwnProperty('materialLvl1.id'),
          ),
        // if final universe is unchecked, remove categoryLvl0, categoryLvl1 and categoryLvl2
        (f) =>
          removeFilterGroup(
            f,
            'categoryLvl2.id',
            filterGroupName === 'universe.id' && f.hasOwnProperty('categoryLvl2.id'),
          ),
        (f) =>
          removeFilterGroup(
            f,
            'categoryLvl1.id',
            filterGroupName === 'universe.id' && f.hasOwnProperty('categoryLvl1.id'),
          ),
        (f) =>
          removeFilterGroup(
            f,
            'categoryLvl0.id',
            filterGroupName === 'universe.id' && f.hasOwnProperty('categoryLvl0.id'),
          ),
        // finally remove the filterGroup since it's empty
        (f) => removeFilterGroup(f, filterGroupName),
      )(newState);

      return modifiedState;
    });
  };

  const checkModelFilters = (sendTrackingEvent: SendTrackingEvent, filterName?: string) => {
    const selectedModels = facets.model.filter((model) => model.name === filterName).map((model) => model.id);
    const newState = {
      ...filters,
      // model.id may not exist, so create an empty array in that case
      'model.id': [...(filters['model.id' as ElasticSearchStringListFilterProperties] ?? []), ...selectedModels],
    };

    setFilters(newState);

    sendTrackingEvent(newState);
  };
  /***
   END
   HELPER METHODS FOR HANDLING TOGGLE FILTERS
   *****/

  // This is the main function that handles the toggling of filters
  const toggleFilter = (filterGroup: ElasticSearchFacetsFields, filterIds?: string[], filterName?: string): void => {
    isManualSwitchRef.current = true;
    const sendTrackingEvent: SendTrackingEvent = (
      newFilterState: ElasticSearch['filters'],
      filterType = facetToFilterRename(filterGroup),
    ) => {
      const filterValue = getAnalyticsCompliantFilterValue({
        filterGroup,
        filterIds,
        filterName,
      });
      sendSnowplowEvent(newFilterState, { [filterType]: filterValue });
    };

    // this is responsible for handing the removal of the price chip - setting the price filter is done elsewhere
    if (filterGroup === 'price') {
      removePriceChipFilter(filterIds);
      return;
    }

    if (filterGroup === 'directShippingEligible') {
      handleDirectShippingFilter(sendTrackingEvent);
      return;
    }

    // Some filterGroups must be renamed from their facet naming, to comply with the API
    const filterGroupName = facetToFilterRename(filterGroup);

    if (filterGroup === 'localCountries' && !!freeShippingFilter) {
      const isChecked = filters.hasOwnProperty('localCountries');
      filterFreeShipping(sendTrackingEvent, isChecked);
      return;
    }

    // If the user has activated the 'local country' filter, maps the country filter to the specified list
    if (filterGroup === 'localCountries' && filters.country && !filters.localCountries) {
      filterLocalCountries(sendTrackingEvent);
      return;
    }

    // For boolean properties, we just toggle between them being present or not
    if (isBooleanFilterKey(filterGroupName)) {
      handleBooleanFilter(filterGroupName, sendTrackingEvent);
      return;
    }

    // Non-boolean properties, the 'as' is fine, because we filtered out booleans earlier
    const isChecked: boolean = !!filters[filterGroupName as ElasticSearchStringListFilterProperties]?.find((filter) =>
      filterIds.includes(filter),
    );

    // Uncheck if checked
    if (isChecked && isStringListFilterKey(filterGroupName)) {
      uncheckStringListFilter(filterGroupName, filterIds, filterName);
      return;
    }

    // Check all models with the same name
    if (filterGroupName === 'model.id') {
      checkModelFilters(sendTrackingEvent, filterName);
      return;
    }

    const newState = {
      ...filters,
      // ...prevState[filterGroup] may not exist, so create an empty array in that case
      [filterGroupName]: [...(filters[filterGroupName as ElasticSearchStringListFilterProperties] ?? []), ...filterIds],
    };

    // Check boolean filter if unchecked
    setFilters(newState);

    sendTrackingEvent(newState);
  };

  const removeAllFilters = (): void => {
    setFilters((prevState: ElasticSearch['filters']) => {
      const newFilterState: ElasticSearch['filters'] = {};

      // keep catalogLinksWithoutLanguage
      if (prevState.hasOwnProperty('catalogLinksWithoutLanguage')) {
        newFilterState.catalogLinksWithoutLanguage = prevState.catalogLinksWithoutLanguage;
      }

      // keep campaign filter
      if (prevState.hasOwnProperty('campaign.id')) {
        newFilterState['campaign.id'] = prevState['campaign.id'];
      }

      // keep static filters
      Object.keys(staticFilterGroups).forEach((filterGroup: ElasticSearchFacetsFields) => {
        const filterKey: string = facetToFilterRename(filterGroup);
        if (staticFilterGroups[filterGroup] && prevState[filterKey]) {
          newFilterState[filterKey] = prevState[filterKey];
        }
      });

      // if we are on multi-universe page and my sizes is not enabled, remove the universe filter also
      if (applicableSizeIds.length > 1 && !areMySizesEnabled && newFilterState['universe.id']) {
        const { 'universe.id': removed, ...finalState } = newFilterState;
        return finalState;
      }

      return newFilterState;
    });
  };

  const updatePriceRangeFilter = (min: number, max: number): void => {
    isManualSwitchRef.current = true;
    const price: ElasticSearch['filters']['price'] = {
      ...(min > 0 && {
        '>=': min,
      }),
      ...(max > 0 && {
        '<=': max,
      }),
    };
    const newState = {
      ...filters,
      price,
    };
    setFilters(newState);
    const minFilterValue = price['>='] ? `min:${price['>=']}${currency}` : '';
    const maxFilterValue = price['<='] ? `max:${price['<=']}${currency}` : '';
    const filterPriceValue =
      (minFilterValue !== '' && maxFilterValue !== '' ? `${minFilterValue}_` : minFilterValue) + maxFilterValue;

    sendSnowplowEvent(newState, { price: filterPriceValue });
  };

  return { toggleFilter, removeAllFilters, updatePriceRangeFilter };
};

export default useUpdateFilters;
