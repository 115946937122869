import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { updateParamInUrl } from '@helpers/routing';
import ProfileItemsForSale from '../profile-items-for-sale/profile-items-for-sale';
import styles from './profile-tabs.module.scss';

const ProfileFavorites = dynamic(() => import('@components/organisms/profile/profile-favorties/profile-favorites'), {
  ssr: false,
});

export type TabList = 'items-for-sale' | 'favorites' | '';

const ProfileTabs: React.FC = () => {
  const { t } = useTranslation('profile');
  const router = useRouter();
  const { sendAnalyticEvent: sendProfileAnalyticEvent } = useAnalyticEvents('profile_page');
  const [tabActive, setTabActive] = useState(router?.query?.tab ? router.query.tab.toString() : 'items-for-sale');

  const navigateTab = (tabName: TabList) => {
    sendProfileAnalyticEvent('profile_click_tab', {
      label: tabName,
    });
    setTabActive(tabName);
    updateParamInUrl('tab', tabName);
  };

  useEffect(() => {
    updateParamInUrl('tab', tabActive);
  }, []);

  return (
    <section className={styles.profileTabs}>
      <ul
        role="tablist"
        className={clsx(styles.profileTabs__nav, 'innerContainer')}
      >
        <li
          role="tab"
          className={clsx(
            styles.profileTabs__nav__tab,
            tabActive === 'items-for-sale' && styles['profileTabs__nav__tab--active'],
          )}
          onClick={() => navigateTab('items-for-sale')}
          data-cy="profile-tab-items-for-sale"
        >
          {t('PROFILE.TABS.ITEM_FOR_SALE')}
        </li>
        <li
          role="tab"
          className={clsx(
            styles.profileTabs__nav__tab,
            tabActive === 'favorites' && styles['profileTabs__nav__tab--active'],
          )}
          onClick={() => navigateTab('favorites')}
          data-cy="profile-tab-favorites"
        >
          {t('PROFILE.TABS.FAVORITES', 'Favorites')}
        </li>
      </ul>

      {tabActive === 'items-for-sale' && <ProfileItemsForSale />}
      {tabActive === 'favorites' && <ProfileFavorites />}
    </section>
  );
};

export default ProfileTabs;
