import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import useOnClickOutside from '@hooks/browser-events/use-on-click-outside';
import useOnKeyPress from '@hooks/browser-events/use-on-key-press';
import useWindowSize from '@hooks/use-window-size';
import MorphingArrow from '@components/catalog/filter-group/components/filter-group-container/morphing-arrow';
import ProfileFilterOptions from './profile-filter-options/profile-filter-options';
import styles from './profile-filter.module.scss';

type ProfileFilterProps = {
  onChange?: (type: string | string[], soldItemState?: number, text?: string[]) => void;
  open: boolean;
  openDialog: (isOpen: boolean) => void;
  soldItemState?: number;
};

const ProfileFilter: React.FC<ProfileFilterProps> = (props) => {
  const { onChange, open, openDialog, soldItemState } = props;
  const [isDepartmentExpanded, setIsDepartmentExpanded] = useState(true);
  const [isSoldProductExpanded, setIsSoldProductExpanded] = useState(true);

  const { t } = useTranslation('profile');
  const { isWiderThanMd } = useWindowSize();

  const selectRef = useRef<HTMLDivElement>(null);

  const escPress = useOnKeyPress('Escape', selectRef);
  useOnClickOutside(selectRef, () => openDialog(false));

  const [selectedFilterText, setSelectedFilterText] = useState([]);
  const [selectedDepartmentValue, setSelectedDepartmentValue] = useState([]);
  const [selectedSoldItemState, setSelectedSoldItemState] = useState(soldItemState);

  const toggleDialog = () => {
    openDialog(!open);
  };

  const handleChange = (value) => {
    if (isWiderThanMd) {
      onChange(value);
    }
  };

  const applyChange = () => {
    onChange(selectedDepartmentValue, selectedSoldItemState, selectedFilterText);
    openDialog(false);
  };

  const handleDepartmentChange = (event, value: string, text: string) => {
    let filterValue;
    if (event.target.checked) {
      setSelectedDepartmentValue((prevValues) => [...prevValues, value]);
      setSelectedFilterText((prevTexts) => [...prevTexts, text]);
      filterValue = [...selectedDepartmentValue, value];
    } else {
      setSelectedDepartmentValue((prevValues) => prevValues.filter((v) => v !== value));
      setSelectedFilterText((prevTexts) => prevTexts.filter((t) => t !== text));
      filterValue = selectedDepartmentValue?.filter((t) => t !== value);
    }
    handleChange(filterValue);
  };

  const handleSoldProductChange = (event, value: string, text: string) => {
    setSelectedSoldItemState(event.target.checked ? Number(value) : undefined);
    setSelectedFilterText([text]);
  };

  useEffect(() => {
    if (escPress) {
      openDialog(false);
    }
  }, [escPress]);

  return (
    <div
      className={styles.profileFilter}
      ref={selectRef}
    >
      <span className="vc-d-none vc-d-md-inline-block">{t('PROFILE.ITEMS_FOR_SALE.FILTER_BY')}</span>
      <Button
        data-cy="profile-items-for-sale-filter-popup-toggle-button"
        disableDefaultStyling
        className={clsx(styles.profileFilter__button, 'vc-d-none', 'vc-d-md-inline-block', 'open')}
        onClick={() => toggleDialog()}
      >
        {selectedDepartmentValue?.length === 0
          ? t('PROFILE.ITEMS_FOR_SALE.FILTERS.UNIVERSE')
          : selectedFilterText?.join(', ')}
      </Button>
      <div className={clsx(styles.profileFilter__list, { [styles.open]: open })}>
        <div className={styles.profileFilter__listHeader}>
          <span>{t('PROFILE.ITEMS_FOR_SALE.FILTER_BY')}</span>
          <Button
            data-cy="profile-items-for-sale-filter-popup-close-button"
            disableDefaultStyling
            aria-label="close modal"
            className="vc-modal__closeBtn"
            onClick={() => toggleDialog()}
          >
            <span className="vc-modal__closeBtn__crossLeft" />
            <span className="vc-modal__closeBtn__crossRight" />
          </Button>
        </div>
        {!isWiderThanMd && (
          <>
            <div className={clsx(styles.profileFilter__mobileWrapper)}>
              <details className={styles.profileFilter__details}>
                <summary
                  className={styles.profileFilter__accordionSummary}
                  onClick={() => setIsDepartmentExpanded(!isDepartmentExpanded)}
                >
                  <legend className={styles.profileFilter__accordionSummary__label}>
                    <span>{t('PROFILE.ITEMS_FOR_SALE.FILTERS.UNIVERSE')}</span>
                  </legend>
                  <MorphingArrow open={!isDepartmentExpanded} />
                </summary>
                {
                  <ProfileFilterOptions
                    options={[
                      { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE1'), value: '1' },
                      { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE2'), value: '2' },
                      { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE3'), value: '3' },
                    ]}
                    selectedValue={selectedDepartmentValue}
                    onChangeHandler={handleDepartmentChange}
                  ></ProfileFilterOptions>
                }
              </details>
            </div>
            <div className={clsx(styles.profileFilter__mobileWrapper)}>
              <details className={styles.profileFilter__details}>
                <summary
                  className={styles.profileFilter__accordionSummary}
                  onClick={() => setIsSoldProductExpanded(!isSoldProductExpanded)}
                >
                  <legend className={styles.profileFilter__accordionSummary__label}>
                    <span>{t('PROFILE.ITEMS_FOR_SALE.SOLD_FILTER')}</span>
                  </legend>
                  <MorphingArrow open={!isSoldProductExpanded} />
                </summary>
                {
                  <ProfileFilterOptions
                    options={[{ text: t('PROFILE.ITEMS_FOR_SALE.HIDE_SOLD_FILTER'), value: '0' }]}
                    selectedValue={selectedSoldItemState?.toString()}
                    onChangeHandler={handleSoldProductChange}
                  ></ProfileFilterOptions>
                }
              </details>
            </div>
          </>
        )}
        {isWiderThanMd && (
          <ProfileFilterOptions
            options={[
              { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE1'), value: '1' },
              { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE2'), value: '2' },
              { text: t('PROFILE.ITEMS_FOR_SALE.UNIVERSE3'), value: '3' },
            ]}
            selectedValue={selectedDepartmentValue}
            onChangeHandler={handleDepartmentChange}
          ></ProfileFilterOptions>
        )}
        <div className={styles.profileFilter__listAction}>
          <Button
            variant="primary"
            size="large"
            onClick={applyChange}
            data-cy="profile-items-for-sale-filter-popup-confirm-button"
            className={styles.profileFilter__listAction__button}
          >
            {t('PROFILE.ITEMS_FOR_SALE.FILTERS.VALIDATE_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileFilter;
