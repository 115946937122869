import React, { useEffect } from 'react';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import { useTranslation } from 'next-i18next';
import Icon from '@components/atoms/icon/icon';
import useUserFollowingQuery from '@hooks/user/use-user-following-query';
import { User } from '@interfaces/models/user';
import useUser from '@hooks/user/use-user';
import logger from '@helpers/utils/logger/client';
import styles from './user-follow-button.module.scss';

interface UserFollowButtonProps {
  sellerId: User['id'];
  isProductPage?: boolean;
  isProfilePage?: boolean;
  stopPropagation?: boolean;
  triggerSub?: boolean;
  handleClick?: (isFollow?: boolean) => void;
}

const UserFollowButton: React.FC<UserFollowButtonProps> = (props): React.JSX.Element => {
  const {
    sellerId,
    isProductPage = false,
    isProfilePage = false,
    stopPropagation = true,
    triggerSub = false,
    handleClick,
  } = props;

  const { t } = useTranslation();
  const { isAuthenticated, invalidateUserCache } = useUser();

  const { followedUsers, followUser, unfollowUser } = useUserFollowingQuery();

  const isCurrentUserFollowingProductSeller: boolean = followedUsers.includes(sellerId);

  useEffect(() => {
    if (triggerSub) {
      if (!isCurrentUserFollowingProductSeller) {
        followUser(sellerId);
      } else {
        unfollowUser(sellerId);
      }
    }
  }, [triggerSub]);

  const toggleSubscribe = async (event): Promise<void> => {
    if (!isAuthenticated) {
      return;
    }

    event.preventDefault();

    if (stopPropagation) {
      event.stopPropagation();
    }
    try {
      if (!isCurrentUserFollowingProductSeller) {
        await followUser(sellerId);
        handleClick?.(true);
      } else {
        await unfollowUser(sellerId);
        handleClick?.(false);
      }
      invalidateUserCache();
    } catch (error) {
      logger.error('Error while following/unfollowing user', error);
    }
  };

  return (
    <Button
      type="submit"
      key={`${isCurrentUserFollowingProductSeller}`}
      className={clsx(
        styles.followBtn,
        isCurrentUserFollowingProductSeller && styles.subscribe,
        isProductPage && styles['followBtn--productPage'],
        isProfilePage && styles['followBtn--profilePage'],
        (isProductPage || isProfilePage) && 'vc-btn vc-btn--rounded-secondary vc-btn--small',
      )}
      onClick={(e) => toggleSubscribe(e)}
      data-cy="user-follow-toggle-button"
      data-vc-dd-action-name={
        !isCurrentUserFollowingProductSeller ? `user_follow_${sellerId}` : `user_unfollow_${sellerId}`
      }
    >
      {(isProductPage || isProfilePage) && (
        <>
          {isProductPage && (
            <Icon
              className={clsx(styles.followBtn__iconMobile, 'd-md-none')}
              name="me-default"
            />
          )}
          {isCurrentUserFollowingProductSeller && (
            <span
              className={styles.followBtn__block}
              data-cy="followBtn_block"
            >
              {isProductPage && (
                <Icon
                  className={clsx(styles.followBtn__iconMobile, 'd-md-none')}
                  name="tick-small-default"
                />
              )}
              <span className={clsx(isProductPage && `d-none d-md-inline`, styles.followBtn__block__text__regular)}>
                {t('FOLLOW_BTN.ALREADY_FOLLOWING')}
              </span>
            </span>
          )}
          {!isCurrentUserFollowingProductSeller && (
            <span
              className={styles.followBtn__block}
              data-cy="followBtn_block"
            >
              {isProductPage && (
                <Icon
                  className={clsx(styles.followBtn__iconMobile, 'd-md-none')}
                  name="plus-default"
                />
              )}
              <span className={clsx(isProductPage && `d-none d-md-inline`, styles.followBtn__block__text)}>
                {t('FOLLOW_BTN.FOLLOW')}
              </span>
            </span>
          )}
        </>
      )}

      {!isProductPage && !isProfilePage && <div>{t('FOLLOW_BTN.FOLLOW')}</div>}
    </Button>
  );
};

export default UserFollowButton;
