import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { ColorOptions } from '@maps/components/color-swatch-color-map';
import _styles from './option-item.module.scss';
import filterRedesignStyles from './v2/option-item.module.scss';

interface OptionItemInterface {
  checked: boolean;
  label: React.JSX.Element | string;
  htmlId: string;
  disabled?: boolean;
  onChange: (event: Event) => void;
  customLabelClass?: string;
  useFilterRedesignUi?: boolean;
  identifier?: string;
  swatchColor?: ColorOptions;
}

type OptionItemProps = OptionItemInterface & Omit<React.HTMLProps<HTMLDivElement>, 'label'>;

const OptionItem: React.FC<OptionItemProps> = (props): React.JSX.Element => {
  const {
    label,
    checked = false,
    onChange,
    htmlId,
    disabled,
    customLabelClass = '',
    useFilterRedesignUi,
    identifier,
    swatchColor,
  } = props;

  const styles = useMemo(
    () => ({
      ..._styles,
      ...(useFilterRedesignUi ? filterRedesignStyles : {}),
    }),
    [useFilterRedesignUi],
  );

  const [checkedState, setCheckedState] = useState<boolean>(checked);

  const handleChange = (event) => {
    onChange(event);
    setCheckedState(event.target.checked);
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <div className={styles.filters_checkbox}>
      <input
        name="checkItem"
        type="checkbox"
        disabled={disabled}
        checked={checkedState}
        onChange={handleChange}
        id={htmlId}
        className={clsx(styles.filters_checkbox_input, 'vc-checkbox__input')}
        data-cy={identifier}
      />
      <label
        htmlFor={htmlId}
        className={clsx(
          styles.filters_checkbox_label,
          customLabelClass,
          !useFilterRedesignUi && ['vc-checkbox__label', 'vc-text-m'],
        )}
      >
        {swatchColor &&
          (swatchColor.assetUrl ? (
            <img
              className={styles.filters_checkbox__colorSwatchImage}
              height={15}
              width={15}
              alt={typeof label === 'string' ? label : ''}
              src={swatchColor.assetUrl}
            />
          ) : (
            <div
              className={clsx(
                styles.filters_checkbox__colorSwatch,
                swatchColor.noColor && styles.filters_checkbox__colorSwatch__other,
              )}
              style={{
                backgroundColor: swatchColor?.hexadecimal ?? 'transparent',
              }}
            />
          ))}
        {label}
      </label>
      {useFilterRedesignUi && <span className={styles.filters_checkbox_checkmark} />}
    </div>
  );
};

export default OptionItem;
