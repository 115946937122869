import React, { useCallback, useState } from 'react';
import Icon from '@components/atoms/icon/icon';
import { useTranslation } from 'next-i18next';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useUser from '@hooks/user/use-user';
import KebabMenu from '@components/atoms/kebab-menu/kebab-menu';
import styles from './report-user.module.scss';

interface ReportUserProps {
  profileId: string;
  className?: string;
}

const ReportUser: React.FC<ReportUserProps> = (props) => {
  const { t } = useTranslation('profile');
  const { profileId, className } = props;
  const [closeMenu, setCloseMenu] = useState<boolean>(false);
  const { user } = useUser();
  const userEmail = user?.email;
  const isMenuOpen = useCallback(
    (open) => {
      setCloseMenu(!open);
    },
    [closeMenu],
  );
  const { sendAnalyticEvent } = useAnalyticEvents('profile_page');
  const trackDSAClick = () => {
    sendAnalyticEvent('report_user', {
      label: profileId,
    });
  };

  return (
    <KebabMenu
      className={className}
      closeMenu={closeMenu}
      isMenuOpen={isMenuOpen}
    >
      <a
        target="_blank"
        href={t('PROFILE.REPORT_USER.LINK_PATH', { profileId, userEmail })}
        rel="noreferrer noopener"
        className={styles.reportUser}
        onClick={trackDSAClick}
        data-cy="report-user"
      >
        <Icon
          name="block-user-default"
          className={styles.reportUser__icon}
        />
        <span>{t('PROFILE.REPORT_USER.BUTTON', 'Report user')}</span>
      </a>
    </KebabMenu>
  );
};

export default ReportUser;
