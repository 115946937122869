import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import MobileActionBar from '@components/catalog/product-search/mobile-action-bar/mobile-action-bar';
import Sort from '@components/catalog/sort/sort';
import { updateParamInUrl } from '@helpers/routing';
import clsx from 'clsx';
import ProductFeedLayout from '@components/generic-product-feed/product-feed-layout/product-feed-layout';
import ProductFeed from '@components/generic-product-feed/product-feed/product-feed';
import Button from '@components/atoms/button/button';
import { useRouter } from 'next/router';
import useWindowSize from '@hooks/use-window-size';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import useProfileMyItemsForSaleQuery from '@hooks/profile/use-profile-my-items-for-sale-query';
import { DEFAULT_PAGINATION_LIMIT } from '@api/profile';
import Switch from '@components/catalog/switch/switch';
import GenericPagination from '@components/common/generic-pagination/generic-pagination';
import { useAnalytics } from '@context/analytics.context';
import useProfileQuery from '@hooks/profile/use-profile-query';
import ProfileFilter from '../profile-filter/profile-filter';
import styles from './profile-items-for-sale.module.scss';

export interface PaginationQuery {
  limit?: number;
  offset?: number;
}

const ProfileItemsForSale: React.FC = () => {
  const { t } = useTranslation('profile');
  const { updateDataLayer } = useAnalytics();
  const { isUserProfile } = useProfileQuery();

  const router = useRouter();
  const { isWiderThanMd } = useWindowSize();
  const { profileSlug, sortBy: sortOrder } = router?.query as {
    profileSlug: string[];
    sortBy: ElasticSearch['sortBy'];
  };
  const profileId = profileSlug?.[0] || '';
  const [sortByItem, setSortBy] = useState<ElasticSearch['sortBy']>(
    (sortOrder as ElasticSearch['sortBy']) || 'relevance',
  );
  const [filterBy, setFilterBy] = useState([]);
  const [isSortModalOpen, setIsSortModalOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [areMobileFiltersOpen, setAreMobileFiltersOpenedState] = useState<boolean>(false);
  const [paginationQuery, setPaginationQuery] = useState<PaginationQuery | null>({
    limit: DEFAULT_PAGINATION_LIMIT,
    offset: 0,
  });
  const [soldItemState, setSoldItemState] = useState<number>(0);

  const { itemsForSale, paginationStats, isLoadingProducts, searchPayload } = useProfileMyItemsForSaleQuery({
    profileId,
    sortBy: sortByItem,
    filterBy,
    paginationQuery,
    soldItemState,
  });
  const [products, setProducts] = useState(itemsForSale);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGINATION_LIMIT);

  useEffect(() => {
    const screenPath = isUserProfile ? 'my_items_for_sale' : 'members_items_for_sale';

    updateDataLayer({
      screen_category: 'profile',
      screen_name: `/${screenPath}`,
      screen_subcategory: screenPath,
    });
  }, [isUserProfile]);

  useEffect(() => {
    if (currentPage > 0) {
      setProducts((oldProducts) => [...oldProducts, ...itemsForSale]);
    } else {
      setProducts(itemsForSale);
    }
  }, [itemsForSale, currentPage]);

  const onSortChange = (type: ElasticSearch['sortBy']) => {
    setSortBy(type);
    router.query.sortBy = type;
  };

  const onFilterChange = (departmentFilterType, soldItemState?: number, selectedFilterText?: string[]) => {
    if (selectedFilterText?.includes(t('PROFILE.ITEMS_FOR_SALE.HIDE_SOLD_FILTER'))) {
      setSoldItemState(soldItemState === undefined ? 1 : Number(soldItemState));
    }
    setFilterBy(departmentFilterType);
  };

  const getEmptyStateTitle = useCallback(
    (isUserProfile) => {
      const filterState = searchPayload?.filters;
      const keys = Object.keys(filterState);
      const hasOnlySoldFilter = keys.length === 2 && keys.includes('sold') && Number(filterState?.sold?.[0]) === 1;
      const hasFilterApplied = filterState?.hasOwnProperty('universe.id') || Number(filterState?.sold?.[0]) === 0;

      if (hasOnlySoldFilter) {
        return {
          title: t('PROFILE.ITEMS_FOR_SELL.NO_SOLD_ITEMS_TITLE', 'No sold items'),
          subTitle: isUserProfile
            ? t('PROFILE.ITEMS_FOR_SELL.MY_NO_SOLD_ITEMS_SUBTITLE', 'You have not sold any items yet')
            : t('PROFILE.ITEMS_FOR_SELL.MEMBER_NO_SOLD_ITEMS_SUBTITLE', 'This user has not sold any items yet'),
        };
      } else if (hasFilterApplied) {
        return {
          title: t('PROFILE.ITEMS_FOR_SELL.NO_MATCHING_FILTER_TITLE', 'No items available'),
          subTitle: t('PROFILE.ITEMS_FOR_SELL.NO_MATCHING_FILTER_SUBTITLE', 'No items are matching your filters'),
        };
      } else {
        return {
          title: t('PROFILE.ITEMS_FOR_SELL.FALLBACK.TITLE', 'No items for sale'),
          subTitle: t('PROFILE.ITEMS_FOR_SELL.FALLBACK.SUBTITLE', 'No items listed for now'),
        };
      }
    },
    [searchPayload?.filters],
  );

  const profileMyItemsForSaleEmptyState = useMemo(() => {
    const { title, subTitle } = getEmptyStateTitle(isUserProfile) || {};

    return {
      titleText: title,
      subTitleText: subTitle,
      ctaText: isUserProfile ? t('PROFILE.ITEMS_FOR_SELL.FALLBACK.BUTTON', 'Start selling') : '',
      clickHandler: () => {
        router.push({ pathname: '/sell-clothes-online' });
      },
      pageType: 'profile_items_for_sale',
      image: { path: '/images/profile-page/bag-price.svg' },
    };
  }, [searchPayload?.filters]);

  useEffect(() => {
    if (router) {
      const url = new URL(router.asPath, window.location.origin);
      const params = new URLSearchParams(url.search);

      if (sortByItem) {
        params.set('sortBy', sortByItem);
      }
      updateParamInUrl('sortBy', sortByItem);
    }
  }, [sortByItem]);

  const handleItemsPerPageClick = (count: number) => {
    setItemsPerPage(count);
    setPaginationQuery({
      limit: count,
      offset: 0,
    });
  };

  const handlePaginationChange = (page: number, itemsPerPage: number) => {
    const { limit } = paginationQuery;

    setPaginationQuery({
      limit,
      offset: (page - 1) * itemsPerPage,
    });
  };

  const updateCurrentPage = (currentPage: number): void => {
    setCurrentPage(currentPage);
    setPaginationQuery({
      ...paginationQuery,
      offset: currentPage * DEFAULT_PAGINATION_LIMIT,
    });
  };

  const handleSwitchChange = () => {
    setSoldItemState(Number(!soldItemState));
  };

  const showMobileLoadMoreButton = !isWiderThanMd && paginationStats?.totalCount > products?.length;
  const showDesktopPagination = isWiderThanMd && paginationStats?.totalPages > 1;

  return (
    <>
      <div className={styles.profileItemsForSale}>
        <div className={clsx(styles.profileItemsForSale__mobileSort, 'innerContainer')}>
          <MobileActionBar
            onSort={() => setIsSortModalOpen(true)}
            onFilter={() => setAreMobileFiltersOpenedState(true)}
          />
        </div>
        <div className={clsx(styles.profileItemsForSale__actionBar, 'innerContainer')}>
          <ProfileFilter
            onChange={(departmentFilterType, soldItemState, text) =>
              onFilterChange(departmentFilterType, soldItemState, text)
            }
            openDialog={setAreMobileFiltersOpenedState}
            open={areMobileFiltersOpen}
            soldItemState={soldItemState}
          ></ProfileFilter>
          <Sort
            onChange={(type) => onSortChange(type)}
            defaultValue={sortByItem}
            openDialog={setIsSortModalOpen}
            open={isSortModalOpen}
            options={[
              { text: t('PROFILE.ITEMS_FOR_SALE.SORT.RELEVANCE'), value: 'relevance' },
              { text: t('PROFILE.ITEMS_FOR_SALE.SORT.PRICE_ASC'), value: 'price-asc' },
              { text: t('PROFILE.ITEMS_FOR_SALE.SORT.PRICE_DESC'), value: 'price-desc' },
              { text: t('PROFILE.ITEMS_FOR_SALE.SORT.MOST_RECENT'), value: 'recency' },
            ]}
          ></Sort>
          <div className={clsx(styles.profileItemsForSale__actionBar__soldItem, 'vc-d-none vc-d-md-flex')}>
            <label className={styles.profileItemsForSale__actionBar__soldItem__label}>
              {t('PROFILE.ITEMS_FOR_SALE.SOLD_FILTER_DESKTOP', 'Sold items')}
            </label>
            <Switch
              dataCyElementLocator="profile-items-for-sale-soldItems"
              ariaLabelledby={`profile-items-for-sale-soldItems${soldItemState ? '-checked' : ''}`}
              checked={Boolean(soldItemState)}
              onChange={handleSwitchChange}
            />
          </div>
        </div>
      </div>
      <ProductFeedLayout
        productList={
          <ProductFeed
            products={products}
            isLoading={isLoadingProducts}
            pageType="profile-items-for-sale"
            emptyState={profileMyItemsForSaleEmptyState}
          />
        }
      />
      <div className={clsx(styles.profileItemsForSale__pagination, 'innerContainer')}>
        {showMobileLoadMoreButton && (
          <Button
            data-cy="profile-items-for-sale-load-more-products-button"
            className={clsx('vc-btn--full--sm-down', styles.profileItemsForSale__pagination__viewMoreButton)}
            onClick={() => updateCurrentPage(currentPage + 1)}
            variant={'primary'}
            size={'medium'}
          >
            {t('PROFILE.ITEMS_FOR_SALE.FILTER_BUTTON_LOAD_MORE')}
          </Button>
        )}
        {showDesktopPagination && (
          <GenericPagination
            pageType="profile-items-for-sale"
            paginationMeta={paginationStats}
            defaultLimit={DEFAULT_PAGINATION_LIMIT}
            handlePaginationChange={handlePaginationChange}
            handleItemsPerPageClick={handleItemsPerPageClick}
            itemsPerPage={itemsPerPage}
            dataCyPagination={{
              container: 'profile-items-for-sale-pagination-container',
              resultsLabel: 'profile-items-for-sale-results-label',
              resultsItem: 'profile-items-for-sale-results-item',
            }}
          ></GenericPagination>
        )}
      </div>
    </>
  );
};

export default ProfileItemsForSale;
