import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './badge.module.scss';

type SizeType = 'small' | 'medium' | 'large';
type BadgeType = 'default' | 'outline';

export type BadgeProps = {
  onClose?: () => void;
  badgeSize?: SizeType;
  type?: BadgeType;
  useFilterRedesignUi?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const Badge: React.FC<BadgeProps> = (props) => {
  const {
    children,
    className,
    onClose,
    badgeSize = 'large',
    type = 'default',
    tabIndex = 0,
    useFilterRedesignUi = false,
    ...rest
  } = props;

  const closable = useMemo<boolean>(() => typeof onClose === 'function', [onClose]);

  return (
    <div
      role="button"
      className={clsx(
        { [styles.badge]: !className },
        { [styles['badge--crossable']]: closable },
        styles[`badge_size--${badgeSize}`],
        { [styles['badge--outline']]: type === 'outline' },
        { [styles.redesignUi]: useFilterRedesignUi },
        className,
      )}
      onClick={onClose}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onClose?.();
        }
      }}
      tabIndex={tabIndex}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Badge;
