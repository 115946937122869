import React, { FCWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { ElasticSearch, ElasticSearchFacetsFields } from '@interfaces/models/elasticSearch';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { generateURLHashes, isKidsCatalog, parseURLHashes } from '@helpers/utils/catalog/catalog-url-helpers';
import { isCatalogPage } from '@helpers/routing';
import removeInvalidSubcategoryFilters from '@helpers/utils/catalog/remove-invalid-subcategory-filters';
import { CampaignContext } from '@context/campaign.context';
import useUpdateFilters from '@hooks/catalog-context/use-update-filters';
import useFetchProducts from '@hooks/catalog-context/use-fetch-products';
import {
  getRecentSearchLastSearchedDate,
  parseFacets,
  parsePageCount,
  parseProducts,
} from '@helpers/utils/catalog/catalog-parsing-utils';
import useMySizesState from '@hooks/catalog-context/use-mysizes-state';
import useWindowSize from '@hooks/use-window-size';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import useMarketingFiltersQuery from '@hooks/marketing-filters/use-marketing-filters-query';
import { usePreferences } from '@context/preferences.context';
import useChipList from '@hooks/filters/use-chip-list';
import useSearchQueryId from '@hooks/catalog-context/use-search-query-id';
import { facetToFilterRename } from '@maps/catalog/search-product-facets-filters-maps';
import useStaticFilterGroups from '@hooks/catalog-context/use-static-filter-groups';
import useResultsPerPage from '@hooks/catalog-context/use-results-per-page';
import { CatalogContextProps, CatalogProviderProps, PriceBound } from '@interfaces/models/catalogContextProps';

const CatalogContext = createContext<CatalogContextProps>({} as never);

const useFilters = () => {
  const [filtersState, setFiltersState] = useState<ElasticSearch['filters']>(null);
  const filtersRef = useRef<ElasticSearch['filters']>(null);
  const [signal, setSignal] = useState(0);

  const setFilters = useCallback(
    (
      input: ElasticSearch['filters'] | ((prevState: ElasticSearch['filters']) => ElasticSearch['filters']),
      triggerFetch = true,
    ) => {
      if (typeof input === 'function') {
        filtersRef.current = input(filtersRef.current);
      } else {
        filtersRef.current = input;
      }
      if (triggerFetch) {
        setSignal((prevState) => prevState + 1);
      }
      setFiltersState(input);
    },
    [],
  );

  const memoizedFilters = useMemo(() => filtersRef.current, [signal]);

  return { memoizedFilters, setFilters, filters: filtersState };
};

const CatalogProvider: FCWithChildren<CatalogProviderProps> = (props): React.JSX.Element => {
  const {
    filters: filtersProp,
    sortBy: sortByProp,
    page: pageProp,
    query: searchQuery,
    products: productsProp,
    facets: facetsProp,
    priceBound: priceBoundProp,
    metadata,
    applicableSizeIds,
    children,
    filterQuery,
    appendHashesToURL = true,
    scrollRef,
    searchSessionId,
  } = props;
  const { country, currency, language } = usePreferences();
  const campaign = useContextSelector(CampaignContext, (v) => v.campaign);
  const { marketingFilters } = useMarketingFiltersQuery();
  const { isWiderThanMd } = useWindowSize();

  const searchQueryId = useSearchQueryId();

  // This state is here to make sure we don't do double fetches when both a filter and page update
  const shouldFetchResults = useRef<boolean>(false);
  // /search and campaign pages require 2 fetches at the sxtart. One with sizes, if user as sizes, and one with sizes disabled.
  // In case the one with sizes comes back with 0 results, we disable my sizes and use the results from the one with sizes disabled
  const requiresFetchWithoutSizes = useRef<boolean>(!!searchQuery || !!campaign);
  const isManualSwitchRef = useRef<boolean>(false);
  const curUrlRef = useRef<string>();

  // Pagination states
  const [currentPage, setCurrentPage] = useState<number>(pageProp);
  const [pageCount, setPageCount] = useState<number>(1);
  const [totalResults, setTotalResults] = useState<number>(0);

  const [products, setProducts] = useState<CatalogProduct[]>(productsProp);

  // Loading animation should only be shown until we have first set of data
  const [shouldShowLoadingAnimation, setShouldShowLoadingAnimation] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<ElasticSearch['sortBy']>(sortByProp);

  // PriceBound is used to display labels of items min and max cost
  const [priceBound, setPriceBound] = useState<PriceBound>(priceBoundProp);

  const { filters, setFilters, memoizedFilters } = useFilters();

  // Filter display states
  const [facets, setFacets] = useState<ElasticSearchResponse['facets']['fields']>(facetsProp);

  const [correspondingAlertId, setCorrespondingAlertId] = useState<string>('');

  const { staticFilterGroups, setStaticFilterGroups } = useStaticFilterGroups();

  const filterChips = useChipList({ filters, facets, staticFilterGroups });

  const [shouldRenderAllProducts, setShouldRenderAllProducts] = useState<boolean>(false);

  const { setMySizesState, mySizesState } = useMySizesState(applicableSizeIds, curUrlRef, filters, setFilters);

  const { resultsPerPage, setResultsPerPage } = useResultsPerPage({ curUrlRef });

  const { queryResult } = useFetchProducts({
    filters: memoizedFilters,
    currentPage,
    searchQuery,
    facets,
    resultsPerPage,
    sortBy,
    mySizesState,
    setMySizesState,
    setCurrentPage,
    setShouldShowLoadingAnimation,
    isManualSwitchRef,
    requiresFetchWithoutSizes,
    shouldFetchResults,
    searchSessionId,
    assignSearchQueryId: searchQueryId.assignSearchQueryId,
  });

  const facetsOrder = useMemo(() => queryResult?.facetsOrder ?? [], [queryResult]);

  // If the landscape view of a mobile device is larger than medium, we display the items from the current page
  // If switching to portrait view, the product list is set to the last batch of products
  useEffect(() => {
    if (isWiderThanMd) {
      setProducts((prevState) => {
        if (prevState.length <= resultsPerPage) {
          return prevState;
        }

        return prevState.slice(-resultsPerPage);
      });
    }
  }, [isWiderThanMd]);

  // Set new data when query results change
  useEffect(() => {
    if (queryResult) {
      setFacets(parseFacets(queryResult?.facets?.fields, filters));
      // If the device is smaller than medium, we append the new ones
      setProducts(
        parseProducts({
          queryResult,
          country,
          currency,
          language,
          lastSearchedDate: getRecentSearchLastSearchedDate(),
        }),
      );
      setPageCount(parsePageCount(currentPage, queryResult.paginationStats.totalPages));
      setTotalResults(queryResult.paginationStats.totalHits);
      setPriceBound({
        lower: queryResult.facets.stats?.price?.min,
        upper: queryResult.facets.stats?.price?.max,
      });
      // Update filters after fetching data to prevent invalid subcategory filters from being selected
      const { filters: sanitizedFilters, hasChanged } = removeInvalidSubcategoryFilters({
        filters,
        queryResult,
      });
      const universeFacet = queryResult?.facets?.fields?.universe;
      // If we only have a single universe facet, we select it by default
      if (universeFacet?.length === 1) {
        sanitizedFilters['universe.id'] = [universeFacet[0].id];
        setStaticFilterGroups((prevState) => ({
          ...prevState,
          universe: true,
        }));
      } else {
        setStaticFilterGroups((prevState) => ({
          ...prevState,
          universe: false,
        }));
      }
      // Only refetch if filters have changed
      setFilters(sanitizedFilters, hasChanged);
    }
  }, [queryResult]);

  useEffect(() => {
    if (!appendHashesToURL || !(filters && facets)) {
      return;
    }
    const newUrl = generateURLHashes(window.location.href, filters, currentPage, country, facets, sortBy);
    if (curUrlRef.current !== newUrl) {
      let historyMethod = isWiderThanMd ? 'pushState' : 'replaceState';
      // trim the history stack, /men/ & /men/#gender=men ==> /men/#gender=men
      if (Object.keys(filters).length === 2) {
        historyMethod = 'replaceState';
      }
      window.history[historyMethod](null, '', newUrl);
      curUrlRef.current = newUrl;
    }
  }, [appendHashesToURL, currentPage, facets, filters, country, sortBy]);

  const getFiltersFromUrl = useCallback(() => {
    const { filters: initialFilters, pageIndex } = parseURLHashes(
      filterQuery || window.location.href,
      marketingFilters,
    );
    curUrlRef.current = window.location.href;
    return { initialFilters, pageIndex };
  }, [filterQuery, marketingFilters]);

  const setInitialFilters = useCallback(() => {
    const initialFilters = filtersProp ?? {};
    if (!requiresFetchWithoutSizes.current) {
      if (applicableSizeIds && applicableSizeIds.length === 1) {
        initialFilters['universe.id'] = applicableSizeIds.map((id) => id.toString());
      } else if (isKidsCatalog(metadata)) {
        initialFilters['universe.id'] = ['3'];
      }
    }
    setStaticFilterGroups((prevState) => {
      const newstaticFilterGroups = { ...prevState };
      Object.keys(prevState).forEach((key: ElasticSearchFacetsFields) => {
        if (initialFilters[facetToFilterRename(key)]) {
          newstaticFilterGroups[key] = true;
        }
      });
      return newstaticFilterGroups;
    });
    const { initialFilters: initialUrlFilters, pageIndex } = getFiltersFromUrl();
    // reduce an extra search requests on PLP after pageload
    setFilters({ ...initialFilters, ...initialUrlFilters });
    setCurrentPage(pageIndex);
  }, [applicableSizeIds, getFiltersFromUrl, metadata, setFilters]);

  // Initial population of filters
  useEffect(() => {
    setInitialFilters();
  }, []);

  // subsequent population of filters on popstate
  useEffect(() => {
    const popStateHandler = () => {
      // e.g. when navigate back to HP, no need to execute this event handler
      if (!isCatalogPage(window.location.pathname)) {
        return;
      }
      const { initialFilters, pageIndex } = getFiltersFromUrl();
      setFilters(initialFilters);
      setCurrentPage(pageIndex);
    };
    window.addEventListener('popstate', popStateHandler);
    return () => {
      window.removeEventListener('popstate', popStateHandler);
    };
  }, [getFiltersFromUrl]);

  useEffect(() => {
    if (shouldShowLoadingAnimation && filters && facets) {
      // This is quite hacky, but chips are too slow otherwise, and it causes CLS
      setTimeout(() => {
        // Stop showing the loading animation after getting the initial data from the API
        setShouldShowLoadingAnimation(false);
      }, 0);
    }
  }, [filters, facets]);

  const areMySizesEnabled = useMemo(() => mySizesState.enabled, [mySizesState.enabled]);
  const areMySizesApplicable = useMemo(() => mySizesState.applicable, [mySizesState.applicable]);

  const scrollUpProducts = () => {
    if (scrollRef?.current) {
      const headerElement = document.querySelector('#header');
      const headerHeight = headerElement ? headerElement.clientHeight : 0;
      const newTop = scrollRef.current.offsetTop - headerHeight;
      if (window.scrollY > newTop) {
        window.scrollTo({ top: newTop, behavior: isWiderThanMd ? 'smooth' : 'instant' });
      }
    }
  };

  const updateCurrentPage = (currentPage: number, isMobile = false): void => {
    if (isMobile) {
      setShouldShowLoadingAnimation(true);
    }
    setCurrentPage(currentPage);
    shouldFetchResults.current = true;
    scrollUpProducts();
  };

  // Scroll to top on change
  useEffect(() => {
    scrollUpProducts();
  }, [memoizedFilters, areMySizesEnabled, resultsPerPage, sortBy]);

  // this effect is responsible for rendering the remaining products when user scrolls for the first time.
  useEffect(() => {
    const handleFirstScroll = () => {
      if (!shouldRenderAllProducts) {
        window.requestAnimationFrame(() => {
          setShouldRenderAllProducts(true);
        });
        window.removeEventListener('scroll', handleFirstScroll);
      }
    };

    window.addEventListener('scroll', handleFirstScroll);

    return () => {
      window.removeEventListener('scroll', handleFirstScroll);
    };
  }, []);

  const { toggleFilter, removeAllFilters, updatePriceRangeFilter } = useUpdateFilters({
    isManualSwitchRef,
    filters,
    setFilters,
    facets,
    areMySizesEnabled,
    applicableSizeIds,
    staticFilterGroups,
  });

  const keywordSuggestion = useMemo(
    () => ({
      hasSuggestion: !!queryResult?.suggestion,
      isAutoCorrected: !!queryResult?.suggestion?.isApplied,
      suggestedSearchQuery: queryResult?.suggestion?.suggestedQuery,
    }),
    [queryResult],
  );

  const value: CatalogContextProps = {
    // Pagination states
    pageCount,
    currentPage,
    totalResults,

    // Result states
    shouldShowLoadingAnimation,
    products,

    // Filter states
    filters,
    setFilters,
    filterChips,
    resultsPerPage,
    setResultsPerPage,
    sortBy,
    setSortBy,
    priceBound,
    setPriceBound,
    searchQuery,
    areMySizesApplicable,
    areMySizesEnabled,
    mySizesState,
    setMySizesState,
    queryResult,

    // Filter display states
    facets,
    facetsOrder,

    // Functions
    removeAllFilters,
    updateCurrentPage,
    toggleFilter,
    updatePriceRangeFilter,

    isManualSwitchRef,
    searchQueryId,
    shouldRenderAllProducts,
    setShouldRenderAllProducts,
    staticFilterGroups,

    correspondingAlertId,
    setCorrespondingAlertId,

    keywordSuggestion,
  };

  return <CatalogContext.Provider value={value}>{children}</CatalogContext.Provider>;
};

export { CatalogProvider, CatalogContext };
