import React, { FCWithChildren, useEffect, useRef, useState } from 'react';
import { Product } from '@interfaces/models/product';
import { useIntersectionObserver } from 'usehooks-ts';

const LazyLoadProductsWrapper: FCWithChildren<{
  onThresholdCrossed: () => void;
}> = ({ children, onThresholdCrossed }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const intersectionObserver = useIntersectionObserver(containerRef, {
    freezeOnceVisible: true,
    threshold: 0.5,
  });

  useEffect(() => {
    if (intersectionObserver?.isIntersecting) {
      onThresholdCrossed();
    }
  }, [intersectionObserver?.isIntersecting]);

  return (
    <div
      className="impressionTrackingContainer"
      ref={containerRef}
    >
      {children}
    </div>
  );
};

const ProductLazyLoadWrapper: FCWithChildren<{
  index: number;
  onThresholdCrossed: () => void;
  shouldLazyLoadProducts: boolean;
  sliceIndex: number;
}> = ({ children, index, onThresholdCrossed, sliceIndex, shouldLazyLoadProducts }) => {
  return (
    <>
      {shouldLazyLoadProducts && index === sliceIndex - 1 ? (
        <LazyLoadProductsWrapper onThresholdCrossed={onThresholdCrossed}>{children}</LazyLoadProductsWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

const useLazyLoadSimilarProducts = (products: Product[], pageType: string, shouldLazyLoadProducts: boolean) => {
  const sliceIndex = pageType === 'hero-pdp' ? 8 : 12;
  const productsToShow = shouldLazyLoadProducts ? products.slice(0, sliceIndex) : products;

  const [renderedProducts, setRenderedProducts] = useState<Product[]>(productsToShow);

  useEffect(() => {
    setRenderedProducts(productsToShow);
  }, [products]);

  const onThresholdCrossed = () => setRenderedProducts(products);

  return { sliceIndex, onThresholdCrossed, renderedProducts, ProductLazyLoadWrapper };
};

export default useLazyLoadSimilarProducts;
