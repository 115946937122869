import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import Environment from '@config/index';
import RequestError from '@errors/request-error';
import { ApiResponse } from '@interfaces/api';
import { UserProfile } from '@interfaces/models/userProfile';
import { User } from '@interfaces/models/user';

const BASE_URL = Environment.apiBaseUrl;
export const DEFAULT_PAGINATION_LIMIT = 48;

export type GetCommunityPayload = {
  userId: User['id'];
  status: string;
  limit: number;
  offset: number;
};

export const getUserProfile = async (
  profileId: string,
  serverAxiosRequestData?: ServerAxiosRequestData,
): Promise<UserProfile> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);

  const {
    data: { data },
  } = await axios.get<ApiResponse<UserProfile>>(`${BASE_URL}/users/profile/${profileId}`, axiosConfig);
  return data;
};

export const getUserCommunity = async (
  payload: GetCommunityPayload,
  serverAxiosRequestData?: ServerAxiosRequestData,
) => {
  try {
    const { userId, status, limit, offset } = payload;
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);
    axiosConfig.params = {
      ...axiosConfig.params,
      status,
      limit,
      offset,
    };

    const { data } = await axios.get(`${BASE_URL}/users/profile/${userId}/community`, axiosConfig);

    return data;
  } catch (error) {
    throw new RequestError(error?.response?.data?.errors?.detail);
  }
};
