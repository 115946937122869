import React from 'react';
import sanitizer from '@helpers/sanitizer';
import UserBadge from '@enums/userBadge';
import VcImage from '@components/atoms/vc-images/vc-image';
import useProfileQuery from '@hooks/profile/use-profile-query';
import { useBucket } from '@context/bucket.context';
import { useTranslation } from 'next-i18next';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import SellerBadges from '@components/molecules/seller-badges/seller-badges';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import { PROFILE_SELLER_BADGES } from '@helpers/badges';
import Icon from '@components/atoms/icon/icon';
import ReportUser from '@components/molecules/report-user/report-user';
import ProfileDescription from '@components/organisms/profile/profile-description/profile-description';
import ProfileCommunityBlockButtons from '@components/organisms/profile/profile-community/profile-community-block-buttons/profile-community-block-buttons';
import styles from './profile-top-section.module.scss';

const ProfileTopSection: React.FC = () => {
  const { t } = useTranslation('profile');
  const { sendAnalyticEvent } = useAnalyticEvents('profile_page');
  const { profile, isUserProfile } = useProfileQuery();
  const { isFeatureEnabled } = useBucket();
  const showReportButton = (!isUserProfile && isFeatureEnabled((f) => f.reportAnItemOrUser, true)) || false;

  const profileBadges = (userBadgeList: UserBadge[]) => {
    if (userBadgeList) {
      return userBadgeList.filter((badge) => {
        return PROFILE_SELLER_BADGES.includes(badge) ? badge : null;
      });
    }
  };

  const onBadgeClick = (badgeName: string) => {
    sendAnalyticEvent('profile_badge', {
      label: badgeName,
    });
  };

  // todo: this is temporary until we have the picture edit functinality ready on backend
  const handleRedirection = () => {
    window.location.href = t('PROFILE.PICTURE_CHANGE.SEE_MY_ACCOUNT');
  };

  return (
    <section className={styles.profileTop}>
      <figure className={styles.profileTop__picture}>
        <ConditionalWrapper
          condition={isUserProfile}
          wrap={(children) => (
            <button
              type="button"
              onClick={handleRedirection}
              className={styles.profileTop__picture__btn}
            >
              {children}
            </button>
          )}
        >
          {profile?.picture?.path ? (
            <VcImage
              width={200}
              height={200}
              src={profile?.picture?.path}
              alt={profile?.firstname}
              className={styles.profileTop__picture__img}
              data-cy="profile-user-picture"
            />
          ) : (
            <div className={styles.profileTop__picture__letter}>{profile?.firstname.substring(0, 1)}</div>
          )}
        </ConditionalWrapper>
      </figure>
      <div className={styles.profileTop__mainInfoContainer}>
        <div className={styles.profileTop__id}>
          <p className={styles.profileTop__id__name}>
            <span data-cy="profile-user-firstname">{profile?.firstname}</span>
            {profile?.segment === 'B2C' && (
              <span
                className={styles.profileTop__id__name__pro}
                data-cy="profile-user-professional-segment"
                >
                  {t('PROFILE.TOP.PROFESSIONAL.LABEL')}
              </span>
            )}
          </p>
          {profile?.username && (
            <p
              className={styles.profileTop__id__username}
              data-cy="profile-user-username"
            >
              @{profile?.username}
            </p>
          )}
        </div>
        {profileBadges(profile?.badges)?.length > 0 && (
          <div className={styles.profileTop__badges}>
            <SellerBadges
              badges={profile?.badges}
              onBadgeClick={onBadgeClick}
            />
          </div>
        )}
        <div className={styles.profileTop__stats}>
          <p className={styles.profileTop__stats__country}>
            <Icon
              name="location-filled-default"
              className={styles.profileTop__stats__country__icon}
            />
            <span data-cy="profile-user-location">{profile?.country}</span>
          </p>
          <p className={styles.profileTop__stats__items}>
            <span>
              <b data-cy="profile-user-items-for-sell__count">{profile?.statistics?.productsListed ?? '0'}</b>&nbsp;
              {profile?.statistics?.productsListed > 1
                ? t('PROFILE.TOP.ITEMS_FOR_SELL')
                : t('PROFILE.TOP.ITEMS_FOR_SELL_SINGULAR')}
            </span>
            <span>
              <b data-cy="profile-user-items-sold__count">{profile?.statistics?.productsSold ?? '0'}</b>&nbsp;
              {profile?.statistics?.productsSold > 1
                ? t('PROFILE.TOP.ITEMS_SOLD')
                : t('PROFILE.TOP.ITEMS_SOLD_SINGULAR')}
            </span>
          </p>
          {profile?.statistics?.usuallyShipsWithin && (
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t('PROFILE.TOP.SHIPS_IN', { usuallyShipsWithin: profile?.statistics?.usuallyShipsWithin }),
                ),
              }}
              data-cy="profile-user-ships-in-status"
            >
              {}
            </p>
          )}
        </div>
        <ProfileDescription className={styles.profileTop__description} />
      </div>
      <div className={styles.profileTop__community}>
        <ProfileCommunityBlockButtons />
        {showReportButton && (
          <ReportUser
            profileId={profile?.id}
            className={styles.profileTop__reportUser}
          />
        )}
      </div>
    </section>
  );
};

export default ProfileTopSection;
