import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import useOnClickOutside from '@hooks/browser-events/use-on-click-outside';
import useOnKeyPress from '@hooks/browser-events/use-on-key-press';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import useWindowSize from '@hooks/use-window-size';
import styles from './sort.module.scss';

type SortOptions = {
  text: string;
  value: ElasticSearch['sortBy'];
};

type SortProps = {
  defaultValue?: ElasticSearch['sortBy'];
  onChange?: (type: ElasticSearch['sortBy']) => void;
  open: boolean;
  openDialog: (isOpen: boolean) => void;
  options: SortOptions[];
} & React.HTMLProps<HTMLDivElement>;

const Sort: React.FC<SortProps> = (props) => {
  const { defaultValue, onChange, open, openDialog, options } = props;

  const { t } = useTranslation();
  const { isWiderThanMd } = useWindowSize();

  const selectRef = useRef<HTMLDivElement>(null);

  const escPress = useOnKeyPress('Escape', selectRef);
  useOnClickOutside(selectRef, () => openDialog(false));

  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const toggleDialog = () => {
    openDialog(!open);
  };

  const handleChange = (value: ElasticSearch['sortBy']) => {
    setSelectedValue(value);
    // We update on change only on large devices, on others, user must manually apply for change to go through
    if (isWiderThanMd) {
      onChange(value);
      openDialog(false);
    }
  };

  const applyChange = () => {
    onChange(selectedValue);
    openDialog(false);
  };

  useEffect(() => {
    if (escPress) {
      openDialog(false);
    }
  }, [escPress]);

  return (
    <div
      className={styles.catalogSort}
      ref={selectRef}
    >
      <span className="vc-d-none vc-d-md-inline-block">{t('CATALOG.FILTERS.SORT.SORT_BY')}</span>
      <Button
        data-cy="catalog-sort-popup-toggle-button"
        disableDefaultStyling
        className={clsx(styles.catalogSort__button, 'vc-d-none', 'vc-d-md-inline-block', 'open')}
        onClick={() => toggleDialog()}
      >
        {options.find((i) => i.value === selectedValue)?.text}
      </Button>
      <div className={clsx(styles.catalogSort__list, { [styles.open]: open })}>
        <div className={styles.catalogSort__listHeader}>
          <span>{t('CATALOG.FILTERS.SORT.TITLE')}</span>
          <Button
            data-cy="catalog-sort-popup-close-button"
            disableDefaultStyling
            aria-label="close modal"
            className="vc-modal__closeBtn"
            onClick={() => toggleDialog()}
          >
            <span className="vc-modal__closeBtn__crossLeft" />
            <span className="vc-modal__closeBtn__crossRight" />
          </Button>
        </div>
        <div className={styles.catalogSort__listContent}>
          {options.map((option) => (
            <div
              key={option.value}
              tabIndex={0}
              role="button"
              className={styles['radio-option']}
              onClick={() => handleChange(option.value)}
              onKeyPress={() => handleChange(option.value)}
            >
              <input
                className={styles['radio-option__input']}
                type="radio"
                value={option.value}
                checked={option.value === selectedValue}
                tabIndex={-1}
                readOnly
                data-cy={`catalog-sort-popup-option-${option.value}`}
              />
              <span>{option.text}</span>
            </div>
          ))}
        </div>
        <div className={styles.catalogSort__listAction}>
          <Button
            variant="primary"
            size="large"
            fullWidth
            onClick={applyChange}
            data-cy="catalog-sort-popup-confirm-button"
          >
            {t('CATALOG.FILTERS.SORT.VALIDATE_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Sort;
