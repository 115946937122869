import { ProfileQueryKeys } from '@enums/react-query-keys';
import { useQuery } from '@tanstack/react-query';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { usePreferences } from '@context/preferences.context';
import mapCatalogHitToProduct from '@helpers/utils/catalog/map-catalog-hit-to-product';
import SearchService from '@services/search-service';
import { mapCountryToTheSizeType } from '@helpers/utils/catalog/catalog-url-helpers';
import { useMemo } from 'react';

const useProfileMyItemsForSaleQuery = ({ profileId, sortBy, filterBy, paginationQuery, soldItemState }) => {
  const { country, currency, language, regionName } = usePreferences();
  const initialPayload = {
    locale: {
      country,
      language,
      currency,
      sizeType: mapCountryToTheSizeType(regionName),
    },
    filters: { 'seller.id': [profileId] },
    mySizes: null,
  };

  const getPaginationState = (paginationStats) => {
    const { totalHits, limit, offset, totalPages } = paginationStats || {};
    const currentPage = offset / limit + 1;
    return {
      totalPages: totalHits <= limit ? 0 : totalPages,
      currentPage: totalHits <= limit ? 0 : currentPage,
      totalCount: totalHits,
    };
  };

  const searchPayload = {
    ...initialPayload,
    pagination: paginationQuery,
    sortBy,
    filters: {
      ...initialPayload.filters,
      ...(filterBy?.length > 0 && { 'universe.id': filterBy }),
      ...{ sold: [`${soldItemState}`] },
    },
  };

  const { data: searchResponse, isFetching } = useQuery<ElasticSearchResponse>({
    queryKey: [
      ProfileQueryKeys.PROFILE_MY_ITEMS_FOR_SALE,
      profileId,
      sortBy,
      filterBy,
      paginationQuery?.limit,
      paginationQuery?.offset,
      soldItemState,
    ],
    queryFn: () => SearchService.productSearch(searchPayload),
    staleTime: 5 * 60 * 1000,
  });

  const itemsForSale = useMemo(() => {
    return (searchResponse?.items ?? []).map((p, i) => ({
      position: i + 1,
      ...mapCatalogHitToProduct(p, country, currency, language),
    }));
  }, [searchResponse?.items]);

  return {
    itemsForSale,
    paginationStats: getPaginationState(searchResponse?.paginationStats || {}),
    isLoadingProducts: isFetching,
    searchPayload,
  };
};

export default useProfileMyItemsForSaleQuery;
