import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UserMutationKeys, UserQueryKeys } from '@enums/react-query-keys';
import { User } from '@interfaces/models/user';
import UserService from '@services/user-service';
import { getUsersFollowedByCurrentlyAuthenticatedUser } from '@api/authenticated-user';
import useUser from '@hooks/user/use-user';

const useUserFollowingQuery = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();

  const { data: followedUsers } = useQuery<User['id'][]>({
    queryKey: [UserQueryKeys.FOLLOWED_USERS, user?.id],
    queryFn: () => getUsersFollowedByCurrentlyAuthenticatedUser(),
    initialData: [],
    enabled: !!user,
  });

  const { mutateAsync: followUser } = useMutation({
    mutationKey: [UserMutationKeys.FOLLOW_USER],
    mutationFn: (userId: User['id']) => UserService.followUser(userId),
    onMutate: (userId: User['id']) => {
      queryClient.setQueryData([UserQueryKeys.FOLLOWED_USERS, user?.id], (prevData: User['id'][]) => {
        return [...prevData, userId];
      });
    },
  });

  const { mutateAsync: unfollowUser } = useMutation({
    mutationKey: [UserMutationKeys.UNFOLLOW_USER],
    mutationFn: (userId: User['id']) => UserService.unfollowUser(userId),
    onMutate: (userId: User['id']) => {
      queryClient.setQueryData([UserQueryKeys.FOLLOWED_USERS, user?.id], (prevData: User['id'][]) => {
        return prevData.filter((cachedUserId: User['id']) => userId !== cachedUserId);
      });
    },
  });

  return {
    followedUsers,
    followUser,
    unfollowUser,
  };
};

export default useUserFollowingQuery;
