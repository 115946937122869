import React from 'react';
import styles from '@components/catalog/product-search/product-search.module.scss';
import Button from '@components/atoms/button/button';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import { CatalogContext } from '@context/catalog.context';
import getSelectedFiltersCount from '@helpers/utils/catalog/getSelectedFiltersCount';
import { ElasticSearch, ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';

const getMobileFilterCount = (filters: ElasticSearch['filters']) => {
  if (!filters) {
    return 0;
  }
  return Object.keys(filters).reduce((count, filterKey: ElasticSearchFilterKeys) => {
    return count + getSelectedFiltersCount({ filterKey, filters });
  }, 0);
};
const MobileActionBar: React.FC<{ onSort: () => void; onFilter: () => void }> = ({ onSort, onFilter }) => {
  const { t } = useTranslation();
  const filters = useContextSelector(CatalogContext, (v) => v.filters);

  const filterCount = getMobileFilterCount(filters);

  return (
    <div className={styles.catalogPage__actionBar}>
      <Button
        className={styles.catalogPage__actionBar__button}
        onClick={onSort}
      >
        {t('CATALOG.SORT_BUTTON')}
      </Button>

      <Button
        className={styles.catalogPage__actionBar__button}
        onClick={onFilter}
      >
        {t('CATALOG.FILTER_BUTTON')}
        {filterCount > 0 && ' (' + filterCount + ')'}
      </Button>
    </div>
  );
};

export default MobileActionBar;
