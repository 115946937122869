import clsx from 'clsx';
import React from 'react';
import styles from './pagination-button.module.scss';

export type PaginationButtonProps = {
  current?: boolean;
  isArrow?: boolean;
  showText?: boolean;
  customActiveStyle?: boolean;
  currentPage?: number;
} & React.HTMLProps<HTMLButtonElement>;

const PaginationButton: React.FC<PaginationButtonProps> = (props) => {
  const {
    currentPage,
    current = false,
    isArrow = false,
    showText = false,
    children,
    customActiveStyle,
    ...buttonProps
  } = props;

  return (
    <button
      type="button"
      className={clsx(
        styles.paginationButton,
        { [styles['paginationButton--current']]: current },
        {
          [styles['paginationButton--current--customActive']]: current && customActiveStyle,
        },
        { [styles['paginationButton--arrow']]: isArrow },
        { [styles['paginationButton--showText']]: showText },
      )}
      data-cy={currentPage ? `pagination-button-${currentPage}` : 'pagination-button'}
      {...(buttonProps as React.HTMLAttributes<HTMLButtonElement>)}
    >
      {children}
    </button>
  );
};

export default PaginationButton;
