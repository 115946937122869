import UserBadge from '@enums/userBadge';
import React from 'react';
import Icon from '@components/atoms/icon/icon';
import Badge from '@components/common/badge/badge';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import styles from './seller-badges.module.scss';

interface SellerBadgesProps {
  badges: UserBadge[];
  onBadgeClick: (bagdeName: string) => void;
}

const SellerBadges: React.FC<SellerBadgesProps> = (props) => {
  const { badges, onBadgeClick } = props;
  const { t } = useTranslation();

  const badgeContent = {
    'fashion-activist': {
      title: t('PRODUCT.DESCRIPTION.FASHION_ACTIVIST'),
      icon: 'badge-fashion-activist',
      className: '',
      link: '/journal/fashion-activist-badge',
      datacy: 'fashion-activist-badge',
    },
    'trusted-seller': {
      title: t('PRODUCT.DESCRIPTION.TRUSTED_SELLER'),
      icon: 'badge-trusted',
      className: 'vc-badge--trusted',
      link: '/seller-badges',
      datacy: 'trusted-seller-badge',
    },
    'expert-seller': {
      title: t('PRODUCT.DESCRIPTION.EXPERT_SELLER'),
      icon: 'badge-expert',
      className: 'vc-badge--expert',
      link: '/seller-badges',
      datacy: 'expert-seller-badge',
    },
    'official-store': {
      title: t('PRODUCT.DESCRIPTION.BRAND_STORE'),
      className: 'vc-badge--official-store',
      link: '/journal/raas-brand-partner-x-vestiaire-collective/',
      datacy: 'official-store-badge',
    },
  };

  return (
    <>
      {badges?.map((badge) => {
        return (
          <React.Fragment key={badge}>
            {badgeContent[badge] && (
              <a
                onClick={() => onBadgeClick(badgeContent[badge]?.title)}
                href={badgeContent[badge]?.link}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.sellerBadge}
                data-cy={badgeContent[badge]?.datacy}
              >
                <Badge
                  type={'default'}
                  className={clsx('vc-badge vc-badge--small', badgeContent[badge]?.className)}
                  badgeSize="small"
                >
                  {badgeContent[badge]?.icon && (
                    <Icon
                      name={badgeContent[badge]?.icon}
                      className={clsx('icon', styles.sellerBadge__icon)}
                    />
                  )}
                  <span>{badgeContent[badge]?.title}</span>
                </Badge>
              </a>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SellerBadges;
