import React, { useEffect, useState, useRef } from 'react';
import useProfileQuery from '@hooks/profile/use-profile-query';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import styles from './profile-description.module.scss';

interface ProfileDescriptionProps {
  className?: string;
}

const ProfileDescription: React.FC<ProfileDescriptionProps> = ({ className }) => {
  const { profile, isUserProfile } = useProfileQuery();
  const { t } = useTranslation('profile');
  const container = useRef<HTMLDivElement>(null);
  const [showToggle, setShowToggle] = useState(false);
  const [isOverflow, setIsOverflow] = useState(undefined);

  useEffect(() => {
    const { current } = container;
    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;
      setShowToggle(current.scrollHeight > 40);
      setIsOverflow(hasOverflow);
    };
    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [container]);

  return (
    <div className={clsx(styles.profileDescription, className)}>
      {profile?.mood ? (
        <div className={styles.profileDescription}>
          <input
            type="checkbox"
            className={clsx('visuallyhidden', styles.profileDescription__checkbox)}
            id="descriptionToggle"
            onChange={() => {
              setIsOverflow(!isOverflow);
            }}
          />
          <p
            className={styles.profileDescription__text}
            ref={container}
            data-cy="profile-user-description"
          >
            {profile?.mood}
          </p>
          {showToggle && (
            <>
              <label
                className={styles.profileDescription__btn}
                htmlFor="descriptionToggle"
              >
                {isOverflow ? t('PROFILE.TOP.SEE_MORE', 'see more') : t('PROFILE.TOP.SEE_LESS', 'see less')}
              </label>
              {isUserProfile && ` | `}
            </>
          )}
          {/* todo: this is temporary until we have the description edit functinality ready on backend */}
          {false && (
            <button
              className={styles.profileDescription__btn}
              type="button"
            >
              {t('PROFILE.TOP.EDIT', 'edit')}
            </button>
          )}
        </div>
      ) : (
        // todo: this is temporary until we have the description edit functinality ready on backend
        false && (
          <button
            type="button"
            className={styles.profileDescription__btn}
          >
            {t('PROFILE.TOP.ADD_DESCRIPTION', 'add description')}
          </button>
        )
      )}
    </div>
  );
};

export default ProfileDescription;
