// Rules to display the USP block depending position of the current product.
const isUSPBlockPositionMatched = (index: number, nbProducts: number, maxPdtNbPos: number): boolean => {
  const currentPdtNb = index + 1;

  if (currentPdtNb > maxPdtNbPos) {
    return false;
  }
  if (nbProducts >= maxPdtNbPos && currentPdtNb === maxPdtNbPos) {
    return true;
  }
  if (nbProducts > 0 && currentPdtNb === nbProducts) {
    return true;
  }
  return false;
};

export default isUSPBlockPositionMatched;
