import React from 'react';
import clsx from 'clsx';
import styles from './morphing-arrow.module.scss';

const MorphingArrow = ({ open }: { open: boolean }) => (
  <div className={styles.morphingArrow__arrowIcon}>
    <span
      className={clsx(
        styles.morphingArrow__arrowBar,
        styles.morphingArrow__arrowBar__left,
        open && styles.morphingArrow__arrowBar__left__open,
      )}
    />
    <span
      className={clsx(
        styles.morphingArrow__arrowBar,
        styles.morphingArrow__arrowBar__right,
        open && styles.morphingArrow__arrowBar__right__open,
      )}
    />
  </div>
);

export default MorphingArrow;
